<template>
  <div>
    <Sidebar />
    <div class="content">
      <NavBar />
      <main>
        <div class="header">
          <div class="left">
            <h1>Novo Orçamento</h1>
          </div>
        </div>
        <div class="bottom-data">
          <div class="orders">
            <div class="header">
              <i class="bx bx-receipt"></i>
              <h3>Informações</h3>
            </div>
            <div class="row">
              <div class="col-md-4 col-12">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label">
                    Nome do Cliente
                  </label>
                  <input v-model="name_budget" type="text" class="form-control" name="name" placeholder="Nome do Cliente" />
                </div>
              </div>
              <div class="col-md-2 col-12">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label">
                    Telefone
                  </label>
                  <input v-model="phone" type="text" class="form-control" name="name" placeholder="Telefone"
                    v-maska="'(##) # ####-####'" />
                </div>
              </div>
              <div class="col-md-2 col-12">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label">
                    Será Empresa?
                  </label>
                  <div class="input-group mb-1">
                    <select class="form-control" v-model="is_legal">
                      <option value="0">Não</option>
                      <option value="1">Sim</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="col-md-3 col-12">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label">
                    {{ (is_legal == 1) ? 'CNPJ' : 'CPF'}}
                  </label>
                  <input v-if="is_legal == 1" v-model="cpf_cnpj" type="text" class="form-control" name="name"
                    placeholder="CNPJ" v-maska="'##.###.###/####-##'" />
                  <input v-else v-model="cpf_cnpj" type="text" class="form-control" name="name" placeholder="CPF"
                    v-maska="'###.###.###-##'" />
                </div>
              </div>
              <div class="col-md-2 col-12">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label">
                    Tipo de Instalação
                  </label>
                  <div class="input-group mb-1">
                    <select class="form-control" v-model="is_ground">
                      <option value="0">Telhado</option>
                      <option value="1">Solo</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="col-md-3 col-12">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label">
                    Kit
                  </label>
                  <div class="input-group mb-1">
                    <select class="form-control" v-model="kit">
                      <option value="0">Escolher um Kit...</option>
                      <option v-for="item in list_kit" :key="item.id" :value="item.id">{{ item.name }}</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-5" v-if="kit != 0 && filteredKit">
              <div class="col-12"><span class="form-label mb-3">
                  <h6 class="pl-10">Informações do Kit</h6>
                </span>
              </div>
              <div class="col-md-12 col-12">
                <div class="form-input">
                  <label for="formGroupExampleInput" class="form-label">
                    Valor do Kit (R$): {{ filteredKit.value_kit }}
                  </label>
                </div>
                <div class="form-input">
                  <label for="formGroupExampleInput" class="form-label">
                    Potência (kW): {{ filteredKit.power }}
                  </label>
                </div>
                <div class="form-input">
                  <label for="formGroupExampleInput" class="form-label">
                    Quantidade de Paineis: {{ filteredKit.count_panel }}
                  </label>
                </div>
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label">
                    Descrição:
                  </label>
                  <div class="input-group mb-1 table-kit" v-html="filteredKit.description">
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-5" v-if="kit == 0">
              <div class="col-12">
                <span class="form-label mb-3">
                  <h6 class="pl-10">Parametros</h6>
                </span>
              </div>
              <div class="col-md-3 col-12">
                <div class="form-input mb-3">
                  <label for="" class="form-label">Aguardando Escolha do Kit...</label>
                </div>
              </div>
            </div>
            <div class="row mt-5" v-if="kit != 0">
              <div class="col-12"><span class="form-label mb-3">
                  <h6 class="pl-10">Parametros</h6>
                </span></div>
              <div class="col-md-3 col-12">
                <div class="form-input mb-3">
                  <label for="" class="form-label">Valor de Instação (R$)</label>
                  <money3 v-model="value_install" v-bind="configMask"></money3>
                </div>
              </div>
              <div class="col-md-2 col-12">
                <div class="form-input mb-3">
                  <label for="" class="form-label">Insumos (%)</label>
                  <input v-model="supplie" type="number" class="form-control" name="supplie" placeholder="0"
                    step="0.01" />
                </div>
              </div>
              <div class="col-md-2 col-12">
                <div class="form-input mb-3">
                  <label for="" class="form-label">Distância (Km)</label>
                  <input v-model="distance" type="number" class="form-control" name="distance" placeholder="0"
                    step="0.01" />
                </div>
              </div>
              <div class="col-md-2 col-12">
                <div class="form-input mb-3">
                  <label for="" class="form-label">Valor por Km (R$)</label>
                  <money3 v-model="value_distance" v-bind="configMask"></money3>
                </div>
              </div>

              <div class="col-md-2 col-12" v-if="role != 3">
                <div class="form-input mb-3">
                  <label for="" class="form-label">Impostos (%)</label>
                  <input v-model="taxes" type="number" class="form-control" name="taxes" placeholder="0" step="0.01" />
                </div>
              </div>
              <div class="col-md-2 col-12" v-if="role != 3">
                <div class="form-input mb-3">
                  <label for="" class="form-label">Margem (%)</label>
                  <input v-model="margin" type="number" class="form-control" name="margin" placeholder="0"
                    step="0.01" />
                </div>
              </div>
              <div class="col-md-2 col-12">
                <div class="form-input mb-3">
                  <label for="" class="form-label">Comissão - Max: {{ max_commission }}%</label>
                  <input v-if="role == 3" v-model="p_commission" type="number" class="form-control" name="taxes" placeholder="0" step="0.01" :max="max_commission"/>
                  <input v-else v-model="p_commission" type="number" class="form-control" name="taxes" placeholder="0" step="0.01"/>
                </div>
              </div>
              <div class="col-md-2 col-12">
                <div class="form-input mb-3">
                  <label for="" class="form-label">Desconto - Max: {{ max_discount }}%</label>
                  <input v-if="role == 3" v-model="p_discount" type="number" class="form-control" name="taxes" placeholder="0" step="0.01" :max="max_discount"/>
                  <input v-else v-model="p_discount" type="number" class="form-control" name="taxes" placeholder="0" step="0.01"/>
                </div>
              </div>
            </div>
            <div class="row mt-5" v-if="kit == 0">
              <div class="col-12">
                <span class="form-label mb-3">
                  <h6 class="pl-10">Precificação</h6>
                </span>
              </div>
              <div class="col-md-3 col-12">
                <div class="form-input mb-3">
                  <label for="" class="form-label">Aguardando Escolha do Kit...</label>
                </div>
              </div>
            </div>
            <div class="row mt-5" v-if="kit != 0">
              <div class="col-12"><span class="form-label mb-3">
                  <h6 class="pl-10">Precificação</h6>
                </span>
              </div>
              <div class="col-md-3 col-12" v-if="role != 3">
                <div class="form-input mb-3">
                  <label for="" class="form-label">Valor do Kit</label>
                  <money3 v-model="p_kit" v-bind="configMask"></money3>
                </div>
              </div>
              <div class="col-md-3 col-12">
                <div class="form-input mb-3">
                  <label for="" class="form-label">Instalação</label>
                  <money3 v-model="p_install" v-bind="configMask"></money3>
                </div>
              </div>
              <div class="col-md-3 col-12">
                <div class="form-input mb-3">
                  <label for="" class="form-label">Insumo</label>
                  <money3 v-model="p_supplie" v-bind="configMask"></money3>
                </div>
              </div>
              <div class="col-md-3 col-12">
                <div class="form-input mb-3">
                  <label for="" class="form-label">Deslocamento</label>
                  <money3 v-model="p_distance" v-bind="configMask"></money3>
                </div>
              </div>
              <div class="col-md-2 col-12">
                <div class="form-input mb-3">
                  <label for="" class="form-label">Valor de Comissão (R$)</label>
                  <money3 v-model="v_commission" v-bind="configMask" disabled></money3>
                </div>
              </div>
              <div class="col-md-2 col-12">
                <div class="form-input mb-3">
                  <label for="" class="form-label">Valor de Desconto (R$)</label>
                  <money3 v-model="v_discount" v-bind="configMask" disabled></money3>
                </div>
              </div>
              <div class="col-md-3 col-12" v-if="role != 3">
                <div class="form-input mb-3">
                  <label for="" class="form-label">Lucro</label>
                  <money3 v-model="p_amount" v-bind="configMask" disabled></money3>
                </div>
              </div>
              <div class="col-md-3 col-12" v-if="role != 3">
                <div class="form-input mb-3">
                  <label for="" class="form-label">Imposto</label>
                  <money3 v-model="p_taxes" v-bind="configMask"></money3>
                </div>
              </div>
              <div class="col-md-2 col-12">
                <div class="form-input mb-3">
                  <label for="" class="form-label">
                    <strong>Total</strong>
                  </label>

                  <money3 v-model="p_all_amout" v-bind="configMask" :disabled="role == 3"></money3>
                </div>
              </div>
            </div>

            <div class="col-12 mb-3 mt-3" v-if="role != 3">
              <span class="form-label">
                <h6 class="pl-10"> Formas de Pagamento</h6>
              </span>
              <textarea ref="editor" v-model="payment_table"></textarea>
            </div>
            <div class="w-100 d-flex align-items-center justify-content-end mt-5">
              <button class="btn btn-primary btn-all" @click="create">
                <span>Criar Orçamaneto</span>
              </button>
            </div>
          </div>
        </div>
      </main>
    </div>

  </div>
</template>

<script>
import Cookie from 'js-cookie'
import NavBar from "@/components/NavBar.vue";
import Sidebar from "@/components/SideBar.vue";

import {
  ClassicEditor,
  AccessibilityHelp,
  Alignment,
  Autoformat,
  AutoLink,
  Autosave,
  BalloonToolbar,
  BlockQuote,
  Bold,
  Code,
  CodeBlock,
  Essentials,
  FindAndReplace,
  FontBackgroundColor,
  FontColor,
  FontFamily,
  FontSize,
  Heading,
  Highlight,
  HorizontalLine,
  Indent,
  IndentBlock,
  Italic,
  Link,
  Paragraph,
  RemoveFormat,
  SelectAll,
  SpecialCharacters,
  SpecialCharactersArrows,
  SpecialCharactersCurrency,
  SpecialCharactersEssentials,
  SpecialCharactersLatin,
  SpecialCharactersMathematical,
  SpecialCharactersText,
  Strikethrough,
  Subscript,
  Superscript,
  Table,
  TableCaption,
  TableCellProperties,
  TableColumnResize,
  TableProperties,
  TableToolbar,
  TextPartLanguage,
  TextTransformation,
  Underline,
  Undo
} from 'ckeditor5';

import translations from 'ckeditor5/translations/pt-br.js';

import 'ckeditor5/ckeditor5.css';

export default {
  components:{
    NavBar,
    Sidebar,
  },
  data() {
    return {
      name_budget: '',
      phone: '',
      is_legal: 0,
      cpf_cnpj: '',
      is_ground: 0,
      kit: 0,
      kit_value: 0,
      count_panel: 0,
      list_kit: [],
      all_kit: [],
      value_install: 0,
      supplie: 0,
      distance: 0,
      value_distance: 0,
      taxes: 0,
      margin: 0,
      payment_table: '',
      filteredKit: null,
      max_commission: 0,
      p_commission: 0,
      v_commission: 0,
      max_discount: 0,
      p_discount: 0,
      v_discount: 0,
      p_kit: 0,
      p_install: 0,
      p_supplie: 0,
      p_distance: 0,
      p_amount: 0,
      v_amount: 0,
      p_taxes: 0,
      p_all_amout: 0,

      uuid: Cookie.get("link"),
      token: Cookie.get('_token'),
      role: this.$store.state.role,
      editor: ClassicEditor,
    
      configEditor: {
        toolbar: {
          items: [
            'undo',
            'redo',
            '|',
            'findAndReplace',
            'textPartLanguage',
            '|',
            'heading',
            '|',
            'fontSize',
            'fontFamily',
            'fontColor',
            'fontBackgroundColor',
            '|',
            'bold',
            'italic',
            'underline',
            'strikethrough',
            'subscript',
            'superscript',
            'code',
            'removeFormat',
            '|',
            'specialCharacters',
            'horizontalLine',
            'link',
            'insertTable',
            'highlight',
            'blockQuote',
            'codeBlock',
            '|',
            'alignment',
            '|',
            'outdent',
            'indent'
          ],
          shouldNotGroupWhenFull: true
        },
        plugins: [
          AccessibilityHelp,
          Alignment,
          Autoformat,
          AutoLink,
          Autosave,
          BalloonToolbar,
          BlockQuote,
          Bold,
          Code,
          CodeBlock,
          Essentials,
          FindAndReplace,
          FontBackgroundColor,
          FontColor,
          FontFamily,
          FontSize,
          Heading,
          Highlight,
          HorizontalLine,
          Indent,
          IndentBlock,
          Italic,
          Link,
          Paragraph,
          RemoveFormat,
          SelectAll,
          SpecialCharacters,
          SpecialCharactersArrows,
          SpecialCharactersCurrency,
          SpecialCharactersEssentials,
          SpecialCharactersLatin,
          SpecialCharactersMathematical,
          SpecialCharactersText,
          Strikethrough,
          Subscript,
          Superscript,
          Table,
          TableCaption,
          TableCellProperties,
          TableColumnResize,
          TableProperties,
          TableToolbar,
          TextPartLanguage,
          TextTransformation,
          Underline,
          Undo
        ],
        balloonToolbar: ['bold', 'italic', '|', 'link'],
        fontFamily: {
          supportAllValues: true
        },
        fontSize: {
          options: [10, 12, 14, 'default', 18, 20, 22],
          supportAllValues: true
        },
        heading: {
          options: [
            {
              model: 'paragraph',
              title: 'Paragraph',
              class: 'ck-heading_paragraph'
            },
            {
              model: 'heading1',
              view: 'h1',
              title: 'Heading 1',
              class: 'ck-heading_heading1'
            },
            {
              model: 'heading2',
              view: 'h2',
              title: 'Heading 2',
              class: 'ck-heading_heading2'
            },
            {
              model: 'heading3',
              view: 'h3',
              title: 'Heading 3',
              class: 'ck-heading_heading3'
            },
            {
              model: 'heading4',
              view: 'h4',
              title: 'Heading 4',
              class: 'ck-heading_heading4'
            },
            {
              model: 'heading5',
              view: 'h5',
              title: 'Heading 5',
              class: 'ck-heading_heading5'
            },
            {
              model: 'heading6',
              view: 'h6',
              title: 'Heading 6',
              class: 'ck-heading_heading6'
            }
          ]
        },
        language: 'pt-br',
        link: {
          addTargetToExternalLinks: true,
          defaultProtocol: 'https://',
          decorators: {
            toggleDownloadable: {
              mode: 'manual',
              label: 'Downloadable',
              attributes: {
                download: 'file'
              }
            }
          }
        },
        table: {
          contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells', 'tableProperties', 'tableCellProperties']
        },
        translations: [translations]
      },

      configMask: {
        masked: false,
        prefix: 'R$ ',
        suffix: '',
        thousands: '.',
        decimal: ',',
        precision: 2,
        disableNegative: false,
        disabled: false,
        min: null,
        max: null,
        allowBlank: false,
        minimumNumberOfCharacters: 0,
        shouldRound: true,
        focusOnRight: false,
      }
    };
  },
  methods: {
    showAlert(type, msg) {
      let textMsg = msg?.message || msg;
      if (msg.errors) {
        textMsg = Object.values(msg.errors).map(err => err.join(', ')).join('<br>');
      }
      this.$swal({
        icon: type,
        confirmButtonText: 'Confirmar',
        html: textMsg,
      });
    },
    initCKEditor() {
      if (!this.$refs.editor) {
        console.error("Elemento do editor ainda não está disponível.");
        return;
      }
      if (window.CKEDITOR) {
        this.ckeditorInstance = window.CKEDITOR.replace(this.$refs.editor, {
          toolbar: "Full",
          height: 100,
          filebrowserUploadUrl: "/upload",
          filebrowserBrowseUrl: "/browse",
        });

        setTimeout(() => {
          if (this.ckeditorInstance) {
            this.ckeditorInstance.resize("100%", 500);
          }
        }, 1000);

        this.ckeditorInstance.on("change", () => {
          this.payment_table = this.ckeditorInstance.getData();
        });

      } else {
        console.error("CKEDITOR não foi carregado corretamente.");
      }
    },
    async loadParams() {
      try {
        const response = await this.$http.get('user/paramsBudget', {
          headers: { Authorization: `Bearer ${this.token}` },
        });

        Object.assign(this, response.data);

        this.distance = response.data.distance;
        this.value_distance = response.data.value_distance;
        this.taxes = response.data.taxes;
        this.payment_table = response.data.payment_table;

      } catch (error) {
        this.showAlert('error', error.response?.data || 'Erro desconhecido.');
      }
    },
    async loadKits() {
      try {
        const response = await this.$http.get('user/kits', {
          headers: { Authorization: `Bearer ${this.token}` },
        });

        this.all_kits = response.data.items;
        this.filterKits();


      } catch (error) {
        this.showAlert('error', error.response?.data || 'Erro desconhecido.');
      }
    },
    filterKits() {
      this.list_kit = this.all_kits.filter(item => item.is_ground == this.is_ground);
    },
    create() {

      const json = {
        name_budget: this.name_budget,
        phone: this.phone,
        is_legal: this.is_legal,
        cpf_cnpj: this.cpf_cnpj,
        is_ground: this.is_ground,
        kit: this.kit,
        value_install: this.value_install,
        supplie: this.supplie,
        distance: this.distance,
        value_distance: this.value_distance,
        taxes: this.taxes,
        margin: this.margin,
        payment_table: this.payment_table,
        p_kit: this.p_kit,
        p_install: this.p_install,
        p_supplie: this.p_supplie,
        p_distance: this.p_distance,
        p_amount: this.p_amount,
        p_taxes: this.p_taxes,
        p_all_amout: this.p_all_amout,
        filteredKit: this.filteredKit,
        p_commission: this.p_commission,
        p_discount: this.p_discount
      };

      this.$http.post('user/budgets', json, {
        headers: { Authorization: `Bearer ${this.token}` },
      })
        .then((response) => {
          this.showAlert('success', response.data.message)

          this.$router.push('/orcamentos')
          const url = `${this.$api}orcamento/${this.uuid}/${response.data.item}`;
          window.open(url, "_blank");
        })
        .catch(error => this.showAlert('error', error.response?.data || 'Erro desconhecido.'));
    },
    updateInstall() {
      this.p_install =  this.value_install * this.count_panel
    },
    updateSupplie() {
      const baseValue = this.p_kit;
      const supplies = this.supplie;

      this.p_supplie = (supplies / 100) * baseValue;
    },
    updateDistance() {
      this.p_distance = parseFloat(this.distance) * parseFloat(this.value_distance);
    },
    updateAmount() {
      this.p_amount = ((parseFloat(this.margin) / 100) * parseFloat(this.p_kit)) - this.v_discount;
      this.v_amount = ((parseFloat(this.margin) / 100) * parseFloat(this.p_kit));
    },
    updateCommission() {
      
      if(this.p_commission > this.max_commission && this.role == 3){
        this.p_commission = this.max_commission;
        this.showAlert('warning', "Você passou do valor máximo de comissão (" +this.max_commission + "%)")
      }
      
      this.v_commission = (this.p_commission / 100) * this.p_amount;
      this.updateAllAmount();

    },
    updateDiscount() {
      
      if(this.p_discount > this.max_discount && this.role == 3){
        this.p_discount = this.max_discount;
        this.showAlert('warning', "Você passou do valor máximo de desconto (" +this.max_discount + "%)")
      }

      this.v_discount = (this.p_discount / 100) * this.v_amount;
      this.updateAmount()
      this.updateCommission()

    },
    updateTaxes() {
      this.p_taxes = (parseFloat(this.p_install) + parseFloat(this.p_supplie) + parseFloat(this.p_distance) + parseFloat(this.p_amount) + parseFloat(this.v_commission)) * (parseFloat(this.taxes) / 100);
      this.updateAllAmount();
    },
    updateAllAmount() {
      this.p_all_amout =
      parseFloat(this.p_kit) +
      parseFloat(this.p_install) +
      parseFloat(this.p_supplie) +
      parseFloat(this.p_distance) +
      parseFloat(this.p_amount) +
      parseFloat(this.p_taxes) +
      parseFloat(this.v_commission);
    },
  },

  watch: {
    payment_table(newValue) {
      if (this.ckeditorInstance && this.ckeditorInstance.getData() !== newValue) {
        this.ckeditorInstance.setData(newValue);
      }
    },
    kit(newKitId) {
      if (newKitId != 0) {
        this.filteredKit = this.list_kit.find(item => item.id === newKitId) || null;

        this.value_install = this.filteredKit.value_install;
        this.supplie = this.filteredKit.supplie;
        this.margin = this.filteredKit.margin;

        this.max_commission = this.filteredKit.commission;
        this.max_discount = this.filteredKit.discount;

      } else {
        this.filteredKit = null;
      }
    },
    p_commission(){
      this.updateCommission();
      this.updateTaxes();
    },
    p_discount(){
      this.updateDiscount();
    },
    is_ground: "filterKits",
    filteredKit: {
      handler(newKit) {
        this.kit_value = parseFloat(newKit?.value_kit || 0);
        this.count_panel = parseFloat(newKit?.count_panel || 0);
        this.p_kit = parseFloat(this.kit_value);

        this.updateInstall();
        this.updateSupplie();
        this.updateDistance();
        this.updateAmount();
        this.updateTaxes();

      },
      immediate: true,
    },
    taxes() {
      this.updateTaxes()
    },
    value_install: "updateInstall",
    supplie: "updateSupplie",
    distance: "updateDistance",
    value_distance: "updateDistance",
    margin(){
      this.updateAmount();
      this.updateCommission();
      this.updateDiscount();
      
    },
    p_install() {
      this.updateTaxes();
      this.updateAllAmount();
    },
    p_supplie() {
      this.updateTaxes();
      this.updateAllAmount();
    },
    p_distance() {
      this.updateTaxes();
      this.updateAllAmount();
    },
    p_taxes() {
      this.updateAllAmount();
    },
    p_kit() {
      this.updateSupplie();
      this.updateAmount();
      this.updateAllAmount();
    }
  },
  created() {
    this.loadParams();
    this.loadKits();
  },
  mounted(){
    this.$nextTick(() => {
      setTimeout(() => {
        if (this.$refs.editor) {
          this.initCKEditor();
        } else {
          console.error("Editor ainda não disponível no DOM.");
        }
      }, 500); 
    });
  }
};
</script>
