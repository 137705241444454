<template>
  <div>
    <Sidebar />
    <div class="content">
      <NavBar />
      <main>
        <div class="header">
          <div class="left">
            <h1>Kits Solar</h1>
          </div>
        </div>
        <div class="bottom-data">
          <div class="orders">
            <div class="header">
              <i class='bx bx-receipt'></i>
              <h3>Lista</h3>
              <router-link to="/kits/adicionar" data-bs-placement="right" tooltip-bs-title="Adicionar kit">
                <i class="bx bx-plus-circle icon-success"></i>
              </router-link>
            </div>
            <div v-for="(item, index) in list" :key="index" class="accordion-item">
              <button @click="toggle(index)" class="accordion-header">
                {{ item.name }}
              </button>
              <div v-show="activeIndex === index" class="accordion-content">
                <table>
                  <thead>
                    <tr>
                      <th>Nomes</th>
                      <th>Valor</th>
                      <th>Potência</th>
                      <th>Criação</th>
                      <th>Ação</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="kit in item.kits" :key="kit.id">
                      <td>{{ kit.name }}</td>
                      <td>{{ convertAmount(kit.value_kit) }}</td>
                      <td>
                        <span>
                          {{ kit.power.toString().replace('.', ',') }} kW
                        </span>
                      </td>
                      <td>{{ formatDate(1, kit.created_at) }}</td>
                      <td class="actions-icon">
                        <router-link :to="{ name: 'kits-edit', params: { kit: kit.id } }">
                          <span class="bx bx-detail ml-5 mr-5 icon-primary">
                          </span>
                        </router-link>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>

  </div>
</template>
<script>

import Cookie from 'js-cookie'
import NavBar from "@/components/NavBar.vue";
import Sidebar from "@/components/SideBar.vue";

export default {
  components: {
    NavBar,
    Sidebar
  },
  data() {
    return {
      list: [],
      activeIndex: null,
      role: this.$store.state.role,
      author: "",
      token: "",
      
    };
  },
  methods: {
    convertAmount(value) {
      return parseFloat(value).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    },
    toggle(index) {
      this.activeIndex = this.activeIndex === index ? null : index;
    },
    load() {
      this.$http.get("user/list/kits", {
        headers: {
          "Authorization": "Bearer " + this.token
        },
      })
        .then((response) => {
          this.list = response.data.items;

        })
        .catch((error) => {
          if (error.response && error.response.data) {
            this.showAlert('error', error.response.data);
          } else {
            this.showAlert('error', 'Ocorreu um erro desconhecido. Entre em contato com a equipe de desenvolvimento.');
          }

        });
    },
    showAlert(type, msg) {
      var textMsg = "";

      if (msg.message) {


        if (typeof msg.errors === 'object' && msg.errors) {
          for (const key in msg.errors) {
            if (Object.prototype.hasOwnProperty.call(msg.errors, key)) {
              const errorMessages = msg.errors[key];
              textMsg += `${errorMessages.join(', ')}<br>`;
            }
          }
        } else {
          textMsg = `${msg.message}`;
        }
      } else {
        textMsg = `${msg}`;
      }

      this.$swal({
        text: textMsg,
        icon: type,
        confirmButtonText: 'Confirmar',
        html: textMsg
      });
    },
    formatDate(type, date) {
      if (!date) {
        return '';
      }

      const dateFormat = new Date(date);

      if (type == 0) {
        return `${dateFormat.getDate().toString().padStart(2, '0')}/${(dateFormat.getMonth() + 1).toString().padStart(2, '0')}/${dateFormat.getFullYear()}`;
      } else {

        return `${dateFormat.getDate().toString().padStart(2, '0')}/${(dateFormat.getMonth() + 1).toString().padStart(2, '0')}/${dateFormat.getFullYear()} às ${dateFormat.getHours().toString().padStart(2, '0')}:${dateFormat.getMinutes().toString().padStart(2, '0')}`;
      }

    },
    getStatusClass(status) {
      switch (status) {
        case 1: // completed
          return 'status completed';
        case 0: // cancelled
          return 'status cancelled';
      }
    },

    getStatusEmail(status) {
      switch (status) {
        case 1: // completed
          return 'status pending';
        case 2: // cancelled
          return 'status completed';
      }
    },
    getStatusText(status) {
      switch (status) {
        case 1: // completed
          return 'Ativo';
        case 0: // pending
          return 'Inativo';
        default:
          return 'Desconhecido';
      }
    },
    getStatusTextEmail(status) {
      switch (status) {
        case 1: // completed
          return 'Aguardando Verificação';
        case 2: // pending
          return 'Verificação Realizada';
      }
    }
  },
  created() {
    if (Cookie.get('_token')) {

      this.token = Cookie.get('_token')
    }
    this.load();
  },
};
</script>