<template>
  <div>
    <Sidebar />
    <div class="content">
      <NavBar />
      <main>
        <div class="header">
          <div class="left">
            <h1>Dashboard</h1>
            <ul class="breadcrumb">
              <li>Inicio</li>
            </ul>
          </div>
        </div>
        <ul class="insights">
          <li class="box-4" v-if="role == 2 || role == 1">
            <i class="bx bx-message-square-dots"></i>
            <span class="info">
              <h3>{{ projects_open }}</h3>
              <p>Projetos em Aberto</p>
            </span>
          </li>
          <li class="box-8" v-if="role == 2 || role == 1">
            <i class="bx bx-message-square-dots"></i>
            <span class="info">
              <h3>{{ projects_pending }}</h3>
              <p>Projetos em Andamento</p>
            </span>
          </li>
          <li class="box-7" v-if="role == 2 || role == 1">
            <i class="bx bx-message-square-dots"></i>
            <span class="info">
              <h3>{{ projects_survey }}</h3>
              <p>Solicitações de Vistoria</p>
            </span>
          </li>
          <li class="box-1" v-if="role == 2 || role == 1">
            <i class="bx bx-message-square-dots"></i>
            <span class="info" v-if="role == 2 || role == 1">
              <h3>{{ projects }}</h3>
              <p>Projetos Finalizados</p>
            </span>
          </li>
          <li v-if="role == 2" class="box-6">
            <i class="bx bx-group"></i>
            <span class="info">
              <h3>{{ users }}</h3>
              <p>Integradores</p>
            </span>
          </li>
          <li class="box-2" v-if="role == 2 || role == 1">
            <i class="bx bx-coin"></i>
            <span class="info">
              <h3>{{ amountConvert(amount_pending) }}</h3>
              <p>Saldo Devedor</p>
            </span>
          </li>
          <li class="box-3" v-if="role == 2 || role == 1">
            <i class="bx bx-coin"></i>
            <span class="info">
              <h3>{{ amountConvert(amount_paid) }}</h3>
              <p>Saldo Pago</p>
            </span>
          </li>
          <li class="box-6" v-if="role == 2 || role == 1">
            <i class="bx bx-coin"></i>
            <span class="info">
              <h3>{{ amountConvert(amount) }}</h3>
              <p>Saldo Total</p>
            </span>
          </li>
          <li class="box-3" :class="{'w-25': role == 3}">
            <i class="bx bx-note"></i>
            <span class="info">
              <h3>{{ budgets }}</h3>
              <p>Orçamentos</p>
            </span>
          </li>

        </ul>
        <div class="bottom-data" v-if="role == 1">
          <div class="orders">
            <div class="header">
              <i class="bx bx-file"></i>
              <h3>Valores por Projeto</h3>
            </div>
            <table class="mt-4">
              <thead>
                <tr>
                  <th>Potência Minima</th>
                  <th>Potência Maxima</th>
                  <th>Valor do Projeto</th>
                  <th>Valor do RRT</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="fee in fee_projects" :key="fee.id">
                  <td>{{ fee.p_min }} kWp</td>
                  <td>{{ fee.p_max }} kWp</td>
                  <td>{{ amountConvert(fee.amount_project) }}</td>
                  <td>{{ amountConvert(fee.amount_rrt) }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>
<script>

import Cookie from 'js-cookie'
import NavBar from "@/components/NavBar.vue";
import Sidebar from "@/components/SideBar.vue";

export default {
  components: {
    NavBar,
    Sidebar
  },
  data() {
    return {
      subscribers: 0,
      plans: 0,
      news: 0,
      projects: 0,
      projects_open: 0,
      projects_pending: 0,
      projects_survey: 0,
      amount: 0,
      amount_pending: 0,
      amount_paid: 0,
      budgets: 0,
      users: 0,
      role: 0,
      fee_projects: [],
      token: Cookie.get('_token')
    };
  },
  methods: {
    showAlert(type, msg) {
      var textMsg = "";
      if (msg.message) {
        if (typeof msg.errors === 'object' && msg.errors) {
          for (const key in msg.errors) {
            if (Object.prototype.hasOwnProperty.call(msg.errors, key)) {
              const errorMessages = msg.errors[key];
              textMsg += `${errorMessages.join(', ')}<br>`;
            }
          }
        } else {
          textMsg = `${msg.message}`;
        }
      } else {
        textMsg = `${msg}`;
      }

      this.$swal({
        text: textMsg,
        icon: type,
        confirmButtonText: 'Confirmar',
        html: textMsg
      });

    },
    amountConvert(value) {
      const valueAmount = (value) ? value : 0;
      return valueAmount.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    },
    load() {
      this.$http
        .get("/user/home", {
          headers: {
            "Authorization": "Bearer " + this.token
          },
        })
        .then((response) => {

          if (response.data.items.subscribers) {
            this.subscribers = response.data.items.subscribers;
          }

          if (response.data.items.plans) {
            this.plans = response.data.items.plans;
          }

          if (response.data.items.news) {
            this.news = response.data.items.news;
          }

          if (response.data.items.users) {
            this.users = response.data.items.users;
          }

          this.amount = response.data.items.amount;
          this.amount_pending = response.data.items.amount_pending;
          this.amount_paid = response.data.items.amount_paid;

          this.projects_pending = response.data.items.projects_pending;
          this.projects_survey = response.data.items.projects_survey;
          this.projects_open = response.data.items.projects_open;

          this.budgets = response.data.items.budgets;

          this.projects = response.data.items.projects;
          this.fee_projects = response.data.fee;

        })
        .catch((error) => {
          if (error.response && error.response.data) {
            this.showAlert('error', error.response.data);
          } else {
            this.showAlert('error', 'Ocorreu um erro desconhecido. Entre em contato com a equipe de desenvolvimento.');
          }

        });
    },
  },
  created() {
    this.load();
    this.role = this.$store.state.role;
  },
};
</script>