<template>
  <div>
    <Sidebar />
    <div class="content">
      <NavBar />
      <main>
        <div class="header">
          <div class="left">
            <h1>Orçamentos</h1>
          </div>
        </div>
        <div class="bottom-data">
          <div class="orders">
            <div class="header">
              <i class="bx bx-receipt"></i>
              <h3>Lista</h3>
              <router-link to="/orcamentos/adicionar" data-bs-placement="right" tooltip-bs-title="Adicionar Orçamento">
                <i class="bx bx-plus-circle icon-success"></i>
              </router-link>
            </div>
            <div class="row">
              <div class="col-md-4 col-12">
                <div class="form-input mb-3 ">
                  <input v-model="params.search" type="text" class="form-control" name="full_name"
                    placeholder="Pesquisar..." />
                </div>
              </div>

            </div>
            <vue3-datatable 
              :rows="list" 
              :columns="cols" 
              @rowClick="handleRowClick" 
              row-class="clickable-row"
              :sortable="true"
              :pagination="true"
              :pageSize="params.pagesize" 
              :search="params.search" 
              :paginationInfo="paginationInfo"
            >
            </vue3-datatable>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import Cookie from "js-cookie";
import NavBar from "@/components/NavBar.vue";
import Sidebar from "@/components/SideBar.vue";

import Vue3Datatable from "@bhplugin/vue3-datatable";
import "@bhplugin/vue3-datatable/dist/style.css";

export default {
  components: {
    NavBar,
    Sidebar,
    Vue3Datatable,
  },
  data() {
    return {
      uuid: Cookie.get("link"),
      list: [],
      params: {
        current_page: 1,
        pagesize: 10,
        search: '',
        column_filters: [],
      },
      paginationInfo: 'Mostrando {0} a {1} de {2}',
      loading: true,
      token: "",
      cols: [
        { label: "ID", field: "id", sortable: true,  title: "ID" },
        { label: "Nome", field: "name", sortable: true,  title: "Nome do cliente" },
        { label: "Telefone", field: "phone", sortable: true,  title: "Telefone" },
        { label: "Valor Total", field: "amount", sortable: true, title: "Valor total" },
        { label: "Criação", field: "created_at", sortable: true,  title: "Data de criação" }
      ],
    };
  },
  methods: {
    showAlert(type, msg) {
      let textMsg = msg?.message || msg;
      if (msg.errors) {
        textMsg = Object.values(msg.errors).map(err => err.join(", ")).join("<br>");
      }
      this.$swal({
        icon: type,
        confirmButtonText: "Confirmar",
        html: textMsg,
      });
    },
    handleRowClick(row) {
      const url = `${this.$api}orcamento/${this.uuid}/${row.id}`;
      window.open(url, "_blank");
    },
    load() {
      if (!this.token) {
        console.error("Token não encontrado, abortando requisição.");
        this.loading = false;
        return;
      }

      this.$http
        .get("user/budgets", {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        })
        .then((response) => {
          this.list = response.data.items.map((item) => ({
            id: String(item.id),
            name: item.name || "Sem nome",
            phone: item.phone ? String(item.phone) : "Não informado",
            amount: this.convertAmount(item.amount),
            created_at: this.formatDate(1, item.created_at),
          }));

          this.$nextTick(() => {
            this.loading = false;
          });
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
          this.showAlert("error", error.response?.data || "Erro ao carregar orçamentos.");
        });
    },
    convertAmount(value) {
      return parseFloat(value).toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL",
      });
    },
    formatDate(type, date) {
      if (!date) return "";
      const dateFormat = new Date(date);
      return type == 0
        ? `${dateFormat.getDate().toString().padStart(2, "0")}/${(dateFormat.getMonth() + 1).toString().padStart(2, "0")}/${dateFormat.getFullYear()}`
        : `${dateFormat.getDate().toString().padStart(2, "0")}/${(dateFormat.getMonth() + 1).toString().padStart(2, "0")}/${dateFormat.getFullYear()} às ${dateFormat.getHours().toString().padStart(2, "0")}:${dateFormat.getMinutes().toString().padStart(2, "0")}`;
    },
  },
  created() {
    this.token = Cookie.get("_token") || "";

    this.load();
  },
};
</script>
