<template>
  <div>
    <Sidebar />
    <div class="content">
      <NavBar />
      <main>
        <div class="header">
          <div class="left">
            <h1>Novo Kit</h1>
          </div>
        </div>
        <div class="bottom-data">
          <div class="orders">
            <div class="header">
              <i class="bx bx-receipt"></i>
              <h3>Informações</h3>
            </div>
            <div class="row">
              <div class="col-md-4 col-12">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label">
                    Nome do Kit
                  </label>
                  <input v-model="name" type="text" class="form-control" name="name" placeholder="Nome do Kit" />
                </div>
              </div>
              <div class="col-md-2 col-12">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label">
                    Potência (kW)
                  </label>
                  <input v-model="power" type="number" class="form-control" name="power" />
                </div>
              </div>
              <div class="col-md-2 col-12">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label">
                    Qtd. de Paneis
                  </label>
                  <input v-model="count_panel" type="number" class="form-control" name="count_panel" />
                </div>
              </div>
              <div class="col-md-2 col-12">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label">
                    Tipo de Instalação
                  </label>
                  <div class="input-group mb-1">
                    <select class="form-control" v-model="is_ground">
                      <option value="0">Telhado</option>
                      <option value="1">Solo</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="col-md-2 col-12">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label">
                    Valor do Kit (R$)
                  </label>
                  <money3 v-model="value_kit" v-bind="config"></money3>
                </div>
              </div>
              <div class="col-md-3 col-12">
                <div class="form-input mb-3">
                  <label for="" class="form-label">Valor de instalação - R$ {{ convertAmount(value_install *
                    count_panel) || 0 }}</label>
                  <money3 v-model="value_install" v-bind="config"></money3>
                </div>
              </div>
              <div class="col-md-2 col-12">
                <div class="form-input mb-3">
                  <label for="" class="form-label">Insumos (%) - R$ {{ convertAmount((supplie / 100) * value_kit) ||
                    0 }}</label>
                  <input v-model="supplie" type="number" class="form-control" name="supplie_roof" placeholder="0"
                    step="0.01" />
                </div>
              </div>
              <div class="col-md-2 col-12">
                <div class="form-input mb-3">
                  <label for="" class="form-label">Margem (%) - R$ {{ convertAmount((margin / 100) * value_kit) ||
                    0 }}</label>
                  <input v-model="margin" type="number" class="form-control" name="margin" placeholder="0"
                    step="0.01" />
                </div>
              </div>
              <div class="col-md-2 col-12">
                <div class="form-input mb-3">
                  <label for="" class="form-label">Comissão (%) - R$ {{ convertAmount(((margin / 100) * value_kit) * (commission / 100))}}</label>
                  <input v-model="commission" type="number" class="form-control" name="taxes" placeholder="0" step="0.01"/>
                </div>
              </div>
              <div class="col-md-2 col-12">
                <div class="form-input mb-3">
                  <label for="" class="form-label">Desconto (%) - R$ {{ convertAmount(((margin / 100) * value_kit) * (discount / 100))}}</label>
                  <input v-model="discount" type="number" class="form-control" name="taxes" placeholder="0" step="0.01" />
                </div>
              </div>
              <div class="col-12">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label">
                    Descrição
                  </label>

                  <textarea ref="editor" v-model="description"></textarea>
                </div>
              </div>
            </div>
            <div class="w-100 d-flex align-items-center justify-content-end mt-5">
              <button class="btn btn-primary btn-all" @click="create">
                <span>Criar Kit</span>
              </button>
            </div>
          </div>
        </div>
      </main>
    </div>

  </div>
</template>

<script>
import Cookie from 'js-cookie'
import NavBar from "@/components/NavBar.vue";
import Sidebar from "@/components/SideBar.vue";

import {
  ClassicEditor,
  AccessibilityHelp,
  Alignment,
  Autoformat,
  AutoLink,
  Autosave,
  BalloonToolbar,
  BlockQuote,
  Bold,
  Code,
  CodeBlock,
  Essentials,
  FindAndReplace,
  FontBackgroundColor,
  FontColor,
  FontFamily,
  FontSize,
  Heading,
  Highlight,
  HorizontalLine,
  Indent,
  IndentBlock,
  Italic,
  Link,
  Paragraph,
  RemoveFormat,
  SelectAll,
  SpecialCharacters,
  SpecialCharactersArrows,
  SpecialCharactersCurrency,
  SpecialCharactersEssentials,
  SpecialCharactersLatin,
  SpecialCharactersMathematical,
  SpecialCharactersText,
  Strikethrough,
  Subscript,
  Superscript,
  Table,
  TableCaption,
  TableCellProperties,
  TableColumnResize,
  TableProperties,
  TableToolbar,
  TextPartLanguage,
  TextTransformation,
  Underline,
  Undo
} from 'ckeditor5';

import translations from 'ckeditor5/translations/pt-br.js';

import 'ckeditor5/ckeditor5.css';

export default {
  components: {
    NavBar,
    Sidebar,
  },
  data() {
    return {
      name: '',
      value_kit: 0,
      power: 0,
      count_panel: 0,
      is_ground: 0,
      value_install: 0,
      supplie: 0,
      margin: 0,
      commission: 0,
      discount: 0,
      description: '',
      token: Cookie.get('_token'),
      role: this.$store.state.role,
      editor: ClassicEditor,
      configEditor: {
        toolbar: {
          items: [
            'undo',
            'redo',
            '|',
            'findAndReplace',
            'textPartLanguage',
            '|',
            'heading',
            '|',
            'fontSize',
            'fontFamily',
            'fontColor',
            'fontBackgroundColor',
            '|',
            'bold',
            'italic',
            'underline',
            'strikethrough',
            'subscript',
            'superscript',
            'code',
            'removeFormat',
            '|',
            'specialCharacters',
            'horizontalLine',
            'link',
            'insertTable',
            'highlight',
            'blockQuote',
            'codeBlock',
            '|',
            'alignment',
            '|',
            'outdent',
            'indent'
          ],
          shouldNotGroupWhenFull: true
        },
        plugins: [
          AccessibilityHelp,
          Alignment,
          Autoformat,
          AutoLink,
          Autosave,
          BalloonToolbar,
          BlockQuote,
          Bold,
          Code,
          CodeBlock,
          Essentials,
          FindAndReplace,
          FontBackgroundColor,
          FontColor,
          FontFamily,
          FontSize,
          Heading,
          Highlight,
          HorizontalLine,
          Indent,
          IndentBlock,
          Italic,
          Link,
          Paragraph,
          RemoveFormat,
          SelectAll,
          SpecialCharacters,
          SpecialCharactersArrows,
          SpecialCharactersCurrency,
          SpecialCharactersEssentials,
          SpecialCharactersLatin,
          SpecialCharactersMathematical,
          SpecialCharactersText,
          Strikethrough,
          Subscript,
          Superscript,
          Table,
          TableCaption,
          TableCellProperties,
          TableColumnResize,
          TableProperties,
          TableToolbar,
          TextPartLanguage,
          TextTransformation,
          Underline,
          Undo
        ],
        balloonToolbar: ['bold', 'italic', '|', 'link'],
        fontFamily: {
          supportAllValues: true
        },
        fontSize: {
          options: [10, 12, 14, 'default', 18, 20, 22],
          supportAllValues: true
        },
        heading: {
          options: [
            {
              model: 'paragraph',
              title: 'Paragraph',
              class: 'ck-heading_paragraph'
            },
            {
              model: 'heading1',
              view: 'h1',
              title: 'Heading 1',
              class: 'ck-heading_heading1'
            },
            {
              model: 'heading2',
              view: 'h2',
              title: 'Heading 2',
              class: 'ck-heading_heading2'
            },
            {
              model: 'heading3',
              view: 'h3',
              title: 'Heading 3',
              class: 'ck-heading_heading3'
            },
            {
              model: 'heading4',
              view: 'h4',
              title: 'Heading 4',
              class: 'ck-heading_heading4'
            },
            {
              model: 'heading5',
              view: 'h5',
              title: 'Heading 5',
              class: 'ck-heading_heading5'
            },
            {
              model: 'heading6',
              view: 'h6',
              title: 'Heading 6',
              class: 'ck-heading_heading6'
            }
          ]
        },
        language: 'pt-br',
        link: {
          addTargetToExternalLinks: true,
          defaultProtocol: 'https://',
          decorators: {
            toggleDownloadable: {
              mode: 'manual',
              label: 'Downloadable',
              attributes: {
                download: 'file'
              }
            }
          }
        },
        table: {
          contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells', 'tableProperties', 'tableCellProperties']
        },
        translations: [translations]
      },
      config: {
        masked: false,
        prefix: 'R$ ',
        suffix: '',
        thousands: '.',
        decimal: ',',
        precision: 2,
        disableNegative: false,
        disabled: false,
        min: null,
        max: null,
        allowBlank: false,
        minimumNumberOfCharacters: 0,
        shouldRound: true,
        focusOnRight: false,
      }
    };
  },
  methods: {
    convertAmount(value) {
      return parseFloat(value).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    },
    clear() {
      this.name = '';
      this.value_kit = 0;
      this.power = 0;
      this.is_ground = 0;
      this.count_panel = 0;
      this.description = '';
    },
    initCKEditor() {
      if (!this.$refs.editor) {
        console.error("Elemento do editor ainda não está disponível.");
        return;
      }
      if (window.CKEDITOR) {
        this.ckeditorInstance = window.CKEDITOR.replace(this.$refs.editor, {
          toolbar: "Full",
          height: 100,
          extraPlugins: "uploadimage,colorbutton,font",
          removePlugins: "notification",
          filebrowserUploadUrl: "/upload",
          filebrowserBrowseUrl: "/browse",
        });

        setTimeout(() => {
          if (this.ckeditorInstance) {
            this.ckeditorInstance.resize("100%", 500);
          }
        }, 1000);

        this.ckeditorInstance.on("change", () => {
          this.description = this.ckeditorInstance.getData();
        });

      } else {
        console.error("CKEDITOR não foi carregado corretamente.");
      }
    },
    showAlert(type, msg) {
      var textMsg = "";

      if (msg.message) {


        if (typeof msg.errors === 'object' && msg.errors) {
          for (const key in msg.errors) {
            if (Object.prototype.hasOwnProperty.call(msg.errors, key)) {
              const errorMessages = msg.errors[key];
              textMsg += `${errorMessages.join(', ')}<br>`;
            }
          }
        } else {
          textMsg = `${msg.message}`;
        }
      } else {
        textMsg = `${msg}`;
      }

      this.$swal({
        icon: type,
        confirmButtonText: 'Confirmar',
        html: textMsg
      });
    },
    create() {

      var json = {
        name: this.name,
        value_kit: this.value_kit,
        power: parseFloat(this.power),
        count_panel: this.count_panel,
        is_ground: this.is_ground,
        description: this.description,
        value_install: this.value_install,
        supplie: this.supplie,
        margin: this.margin,
        discount: this.discount,
        commission: this.commission
      }

      this.$http.post("user/kits", json, {
        headers: {
          Authorization: "Bearer " + this.token,
        },
      })
        .then((response) => {
          this.showAlert("success", response.data.message);
          this.$router.push("/kits");
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            this.showAlert("error", error.response.data);
          } else {
            console.log(error)
            this.showAlert(
              "error",
              "Ocorreu um erro desconhecido. Entre em contato com a equipe de desenvolvimento."
            );
          }
        });
    },
  },
  watch: {
    description(newValue) {
      if (this.ckeditorInstance && this.ckeditorInstance.getData() !== newValue) {
        this.ckeditorInstance.setData(newValue);
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      setTimeout(() => {
        if (this.$refs.editor) {
          this.initCKEditor();
        } else {
          console.error("Editor ainda não disponível no DOM.");
        }
      }, 500);
    });
  }
};
</script>
