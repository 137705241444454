<template>
  <div>
    <Sidebar />
    <div class="content">
      <NavBar />
      <main v-readonly="isReadonly">
        <div class="header">
          <div class="left">
            <h1>{{ this.project_name }}</h1>
            <ul class="breadcrumb">
              <li>Projeto - Solar</li>
            </ul>
          </div>
        </div>

        <div class="bottom-data">
          <div class="orders">
            <div class="header">
              <i class="bx bx-receipt"></i>
              <h3>Informações gerais</h3>
            </div>
            <div class="row">
              <div class="col-md-6 col-12">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label">
                    Nome do projeto
                  </label>
                  <input readonly v-model="project_name" type="text" class="form-control" name="project_name"
                    placeholder="Digite o Nome do projeto" />
                </div>
              </div>
              <div class="col-md-3 col-12">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label">
                    Unidade Consumidora
                  </label>
                  <input readonly v-model="consumer_unit" type="text" class="form-control" name="project_name"
                    placeholder="Informe a UC" />
                </div>
              </div>
              <div class="col-md-3 col-12">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label">
                    Será Empresa?
                  </label>
                  <div class="input-group mb-1">
                    <select class="form-control" v-model="is_legal_entity" @change="legalEntity">
                      <option value="0">Não</option>
                      <option value="1">Sim</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="col-12" :class="{ 'col-md-6': !is_legal_entity, 'col-md-5': is_legal_entity }">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label">
                    Nome do titular da UC
                  </label>
                  <input readonly v-model="uc_holder_name" type="text" class="form-control" name="uc_holder_name"
                    placeholder="Digite o Nome do projeto" />
                </div>
              </div>
              <div class="col-md-3 col-6">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label">
                    RG
                  </label>
                  <input readonly v-model="rg" type="text" class="form-control" name="rg" placeholder="0123456" />
                </div>
              </div>
              <div v-if="is_legal_entity" class="col-md-4 col-6">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label">
                    Nome da Empresa
                  </label>
                  <input readonly v-model="company_name" type="text" class="form-control" name="company_name"
                    placeholder="Razão Social" />
                </div>
              </div>
              <div class="col-md-3 col-6">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label">
                    {{ !is_legal_entity ? 'CPF' : 'CNPJ' }}
                  </label>
                  <input readonly v-if="!is_legal_entity" v-model="cpf_cnpj" type="text" class="form-control"
                    name="cpf_cnpj" placeholder="000.000.000.-00" @input="autoUser" v-maska="'###.###.###-##'" />
                  <input readonly v-else v-model="cpf_cnpj" type="text" class="form-control" name="cpf_cnpj"
                    placeholder="01.001.001/0001-01" @input="autoUser" v-maska="'##.###.###/####-##'" />
                </div>
              </div>
              <div class="col-md-4 col-6">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label" readonly>
                    E-mail
                  </label>
                  <input v-model="email" type="email" class="form-control" name="email" readonly />
                </div>
              </div>
              <div class="col-md-3 col-6">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label">
                    Telefone
                  </label>
                  <input readonly v-maska="'(##) #####-####'" v-model="phone" class="form-label" />
                </div>
              </div>
              <div class="col-md-3 col-6">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label">
                    CEP
                  </label>
                  <input readonly v-maska="'#####-###'" v-model="cep" class="form-label" @input="search" />
                </div>
              </div>
              <div class="col-md-3 col-6">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label" readonly>
                    Cidade
                  </label>
                  <input readonly v-model="city" type="text" class="form-control" name="city"
                    placeholder="Aguardando..." />
                </div>
              </div>
              <div class="col-md-3 col-6">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label" readonly>
                    Estado
                  </label>
                  <input readonly v-model="state" type="text" class="form-control" name="state"
                    placeholder="Aguardando..." />
                </div>
              </div>
              <div class="col-md-6 col-12">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label" readonly>
                    Logradouro
                  </label>
                  <input readonly v-model="street" type="text" class="form-control" name="street"
                    placeholder="Aguardando..." />
                </div>
              </div>
              <div class="col-md-3 col-6">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label" readonly>
                    Bairro
                  </label>
                  <input readonly v-model="neighborhood" type="text" class="form-control" name="neighborhood"
                    placeholder="Aguardando..." />
                </div>
              </div>
              <div class="col-md-2 col-6">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label" readonly>
                    Numero
                  </label>
                  <input readonly v-model="address_number" type="text" class="form-control" name="address_number"
                    placeholder="Ex: 10" />
                </div>
              </div>
              <div class="col-md-6 col-12">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label" readonly>
                    Complemento
                  </label>
                  <input readonly v-model="complement" type="text" class="form-control" name="complement"
                    placeholder="Ex: Casa, APT, Sítio, etc" />
                </div>
              </div>
              <div class="col-md-4 col-12">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label">
                    Situação
                  </label>
                  <div class="input-group mb-1">
                    <select v-if="role == 2" class="form-control" v-model="status">
                      <option value="1">Aberto</option>
                      <option value="2">Em progresso</option>
                      <option value="3">Protocolado</option>
                      <option value="4">Em Parecer de Acesso</option>
                      <option value="5">Obra em andamento</option>
                      <option value="6">Aprovado</option>
                      <option value="7">Vistoria solicitada</option>
                      <option value="8">Finalizado</option>
                      <option value="9">Cancelado</option>
                      <option value="10">Aguardando Solicitação de Vistoria</option>
                    </select>
                    <input type="text" v-else-if="status != 7 && status != 10" :value="statusType(status)" disabled>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-5">
              <div class="col-12">
                <span class="form-label mb-3">
                  <h6 class="pl-10">Informação do Projeto</h6>
                </span>
              </div>
              <div class="col-md-3 col-12">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label">
                    Categoria do Cliente
                  </label>
                  <div class="input-group mb-1">
                    <select class="form-control" v-model="id_customer_category">
                      <option value="0">Escolher...</option>
                      <option value="1">Residencial</option>
                      <option value="2">Rural</option>
                      <option value="3">Comercial</option>
                      <option value="4">Industrial</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="col-md-3 col-12">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label">
                    Tipo de Ligação do Padrão
                  </label>
                  <div class="input-group mb-1">
                    <select class="form-control" v-model="id_connection_type">
                      <option value="0">Escolher...</option>
                      <option value="1">Monofasico</option>
                      <option value="2">Bifasico</option>
                      <option value="3">Trifasico</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="col-md-3 col-12">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label">
                    Disjuntor do Padrão
                  </label>
                  <div class="input-group mb-1">
                    <select class="form-control" v-model="id_circuit_breaker">
                      <option value="0">Escolher...</option>
                      <option value="1">30A</option>
                      <option value="2">40A</option>
                      <option value="3">50A</option>
                      <option value="4">60A</option>
                      <option value="5">63A</option>
                      <option value="6">70A</option>
                      <option value="7">80A</option>
                      <option value="8">100A</option>
                      <option value="9">150A</option>
                      <option value="10">200A</option>
                      <option value="11">250A</option>
                      <option value="12">300A</option>
                      <option value="13">350A</option>
                      <option value="14">400A</option>
                      <option value="15">450A</option>
                      <option value="16">500A</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="col-md-3 col-12">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label">
                    Bitola do Cabo do Padrão
                  </label>
                  <div class="input-group mb-1">
                    <select class="form-control" v-model="id_cable_size">
                      <option value="0">Escolher...</option>
                      <option value="1">6</option>
                      <option value="2">10</option>
                      <option value="3">16</option>
                      <option value="4">25</option>
                      <option value="5">35</option>
                      <option value="6">50</option>
                      <option value="7">70</option>
                      <option value="8">95</option>
                      <option value="9">120</option>
                      <option value="10">185</option>
                      <option value="11">220</option>
                      <option value="12">300</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="col-md-3 col-12">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label">
                    Marca do Modulo
                  </label>
                  <input readonly type="text" class="form-control" name="module_mark" placeholder=""
                    v-model="module_mark" />
                </div>
              </div>
              <div class="col-md-3 col-12">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label">
                    Modelo do Modulo
                  </label>
                  <input readonly type="text" class="form-control" name="module_model"
                    placeholder="Modulo1; Modulo2; etc" v-model="module_model" />
                </div>
              </div>
              <div class="col-md-3 col-12">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label">
                    Potência do Modulo (W)
                  </label>
                  <input readonly type="number" class="form-control" name="module_power" placeholder="Ex: 10"
                    v-model="module_power" />
                </div>
              </div>
              <div class="col-md-3 col-12">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label">
                    Qtd. de Modulo
                  </label>
                  <input readonly type="number" class="form-control" name="module_quantity" placeholder="Ex: 10"
                    v-model="module_quantity" />
                </div>
              </div>
              <div class="col-md-3 col-12">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label">
                    Marca do Inversor
                  </label>
                  <input readonly type="text" class="form-control" name="inverter_mark" placeholder=""
                    v-model="inverter_mark" />
                </div>
              </div>
              <div class="col-md-3 col-12">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label">
                    Modelo do Inversor
                  </label>
                  <input readonly type="text" class="form-control" name="inverter_model"
                    placeholder="Modulo1; Modulo2; etc" v-model="inverter_model" />
                </div>
              </div>
              <div class="col-md-3 col-12">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label">
                    Potência do Inversor (kW)
                  </label>
                  <input readonly type="number" class="form-control" name="inverter_power" placeholder="Ex: 10"
                    v-model="inverter_power" />
                </div>
              </div>
              <div class="col-md-3 col-12">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label">
                    Qtd. de Inversor
                  </label>
                  <input readonly type="number" class="form-control" name="inverter_quantity" placeholder="Ex: 10"
                    v-model="inverter_quantity" />
                </div>
              </div>
              <div class="col-md-3 col-12">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label">
                    Potência do Transformador (Se ouver)
                  </label>
                  <input type="number" class="form-control" name="transformer_power" placeholder="Ex: 10KVA"
                    v-model="transformer_power" />
                </div>
              </div>
            </div>
            <div class="row mt-5">
              <div v-if="role == 2" class="col-12">
                <span class="form-label mb-3">
                  <h6 class="pl-10">Informações Extras</h6>
                </span>
              </div>
              <div v-if="role == 2" class="col-md-4 col-12">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label">
                    Modalidade
                  </label>
                  <div class="input-group mb-1">
                    <select class="form-control" v-model="modality">
                      <option value="0">Escolher...</option>
                      <option value="Geração na própria UC">Geração na própria UC</option>
                      <option value="Auto consumo remoto">Auto consumo remoto</option>
                      <option value="Compartilhada">Compartilhada</option>
                      <option value="Condomínio">Condomínio</option>
                    </select>
                  </div>
                </div>
              </div>
              <div v-if="role == 2" class="col-md-4 col-12">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label">
                    Classes
                  </label>
                  <div class="input-group mb-1">
                    <select class="form-control" v-model="class_group">
                      <option value="0">Escolher...</option>
                      <option value="Comercial">Comercial</option>
                      <option value="Iluminação pública">Iluminação pública</option>
                      <option value="Industrial">Industrial</option>
                      <option value="Poder Público">Poder Público</option>
                      <option value="Residencial">Residencial</option>
                      <option value="Rural">Rural</option>
                      <option value="Serviço Público">Serviço Público</option>
                    </select>
                  </div>
                </div>
              </div>
              <div v-if="role == 2" class="col-md-4 col-12">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label">
                    Subgrupo
                  </label>
                  <div class="input-group mb-1">
                    <select class="form-control" v-model="subgroup">
                      <option value="0">Escolher...</option>
                      <option value="A1">A1</option>
                      <option value="A2">A2</option>
                      <option value="A3">A3</option>
                      <option value="A3a">A3a</option>
                      <option value="A4">A4</option>
                      <option value="AS">AS</option>
                      <option value="B1">B1</option>
                      <option value="B2">B2</option>
                      <option value="B3">B3</option>
                      <option value="B4">B4</option>
                    </select>
                  </div>
                </div>
              </div>
              <div v-if="role == 2" class="col-md-4 col-12">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label">
                    Data da implantação da unidade geradora
                  </label>
                  <input type="datetime-local" class="form-control" name="transformer_power"
                    v-model="installation_date" />
                </div>
              </div>
              <div v-if="role == 2" class="col-md-4 col-12">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label">
                    Data da conexão da unidade Geradora na Distribuidora:
                  </label>
                  <input type="datetime-local" class="form-control" name="transformer_power"
                    v-model="connection_date" />
                </div>
              </div>
              <div class="col-md-3 col-12">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label">
                    Qtd. de UCs que recebem os créditos:
                  </label>
                  <input type="text" class="form-control" name="transformer_power" v-model="credited_ucs_quantity" />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 col-12">
                <div class="row">
                  <div class="col-md-6 col-12">
                    <div class="form-input mb-3">
                      <label for="formGroupExampleInput" class="form-label">
                        Latitude (Graus Decimais)
                      </label>
                      <input type="text" class="form-control" name="transformer_power" v-model="latitude"
                        @input="updateMarker()" />
                    </div>
                  </div>
                  <div class="col-md-6 col-12">
                    <div class="form-input mb-3">
                      <label for="formGroupExampleInput" class="form-label">
                        Longitude (Graus Decimais)
                      </label>
                      <input type="text" class="form-control" name="transformer_power" v-model="longitude"
                        @input="updateMarker()" />
                    </div>
                  </div>
                  <div class="col-12">
                    <p>
                      <strong>Graus, Minutos e Segundos (DMS):</strong> <br>
                      Latitude: {{ convertToDMS(latitude) }} | Longitude: {{ convertToDMS(longitude) }}
                    </p>
                  </div>
                  <div class="col-12">
                    <p><strong>UTM:</strong> <br> Zone: {{ utm.zone }} <br> Longitude UTM: {{ utm.easting }} <br>
                      Latitude UTM: {{ utm.northing }}</p>
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-12">
                <div id="map" style="height: 500px; width: 100%; margin-top: 20px;"></div>
              </div>
            </div>
            <div v-if="role == 2" class="row mt-5">
              <div class="col-12">
                <span class="form-label mb-3">
                  <h6 class="pl-10">Financeiro</h6>
                </span>
              </div>

              <div class="col-md-4 col-12">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label">
                    Qual será o valor do projeto?
                  </label>
                  <div class="input-group mb-1">
                    <money3 v-model="amount_project" v-bind="config"></money3>
                  </div>
                </div>
              </div>
              <div class="col-md-4 col-12">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label">
                    Qual será o valor do ATR/TRT?
                  </label>
                  <div class="input-group mb-1">
                    <money3 v-model="amount_art_trt" v-bind="config"></money3>
                  </div>
                </div>
              </div>
              <div class="col-md-4 col-12">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label">
                    Qual o valor pago?
                  </label>
                  <div class="input-group mb-1">
                    <money3 v-model="amount_paid" v-bind="config"></money3>
                  </div>
                </div>
              </div>

            </div>
            <div class="row">
              <div class="col-12">
                <div class="form-input mt-3">
                  <label for="formGroupExampleInput" class="form-label">
                    Observação
                  </label>
                  <div class="areatext" v-if="!isReadonly">
                    <textarea v-model="observation" placeholder="Descrição do Projeto (Opcional)"></textarea>
                  </div>
                  <div class="areatext areatext-div" v-if="isReadonly" v-html="observation"></div>
                </div>
              </div>
            </div>
            <div v-if="role == 2 || status == 1 || status == 6"
              class="w-100 d-flex align-items-center justify-content-between mt-5 gap-3">
              <div class="d-flex gap-2">
                <button v-if="role == 2" class="btn btn-danger" @click="deleteConfirm">
                  <span>Deletar</span>
                </button>
              </div>
              <button class="btn btn-success" @click="update">
                <span>Editar Projeto</span>
              </button>
            </div>
            <div class="header mt-5">
              <i class="bx bx-file"></i>
              <h3>Lista de Anexos</h3>
            </div>
            <div v-if="role == 2 || status == 1" class="row mb-3">
              <div class="col-12">
                <span class="form-label ">
                  <h6 class="pl-10 mb-3">Adicionar Anexo</h6>
                </span>
              </div>
              <div class="col-md-4 col-12">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label">
                    Nome
                  </label>
                  <div class="input-group mb-1">
                    <input v-model="anexo_name_12" type="text" class="form-control" name="anexo_name_12"
                      placeholder="Nome do arquivo" />
                  </div>
                </div>
              </div>
              <div class="col-md-4 col-12 mb-3">
                <div class="form-input">
                  <label for="formGroupExampleInput" class="form-label">
                    Arquivo
                  </label>
                </div>
                <input readonly id="anexo_12" ref="anexo_12" type="file" class="form-control" name="anexo_12" />
              </div>
              <div class="col-md-3 col-12">
                <div class="form-input">
                  <label for="formGroupExampleInput" class="form-label"></label>
                </div>
                <button class="btn btn-primary btn-all" @click="upload(12)">
                  <span>Adicionar</span>
                </button>
              </div>
            </div>
            <table>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Nome</th>
                  <th>Criação</th>
                  <th>Ação</th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="role == 2">
                  <td>#</td>
                  <td>Formulario Aneel (Gerado Automatico)</td>
                  <td>
                  </td>
                  <td class="actions-icon">
                    <a target="_blank" :href="$api + 'aneel/' + $route.params.projeto">
                      <span data-bs-toggle="tooltip" tooltip-bs-title="Ver detalhes do projeto"
                        class="bx bx-detail ml-5 mr-5 icon-primary">
                      </span>
                    </a>
                  </td>
                </tr>
                <tr v-if="role == 2">
                  <td>#</td>
                  <td>Memorial Descritivo (Gerado Automatico)</td>
                  <td>
                  </td>
                  <td class="actions-icon">
                    <a target="_blank" :href="$api + 'memorial/' + $route.params.projeto">
                      <span data-bs-toggle="tooltip" tooltip-bs-title="Ver detalhes do projeto"
                        class="bx bx-detail ml-5 mr-5 icon-primary">
                      </span>
                    </a>
                  </td>
                </tr>
                <tr v-for="attachment in attachments" :key="attachment.id">
                  <td>{{ attachment.id }}</td>
                  <td>{{ attachment.attach_name }}</td>
                  <td>{{ formatDate(0, attachment.created_at) }}</td>
                  <td class="actions-icon">
                    <a target="_blank" :href="baseStorage + attachment.path" download>
                      <span data-bs-toggle="tooltip" tooltip-bs-title="Ver detalhes do projeto"
                        class="bx bx-detail ml-5 mr-5 icon-primary">
                      </span>
                    </a>
                    <span v-if="role == 2 || status == 1" data-bs-toggle="tooltip" tooltip-bs-title="Deletar documento"
                      class="bx bx-trash ml-5 mr-5 icon-primary" @click="deletarDoc(attachment.id, 12)">
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="bottom-data">
          <div class="orders">
            <div class="header">
              <i class="bx bx-file"></i>
              <h3>Pagamento</h3>
            </div>
            <div class="row mb-3">
              <div class="col-12">
                <span class="form-label ">
                  <h6 class="pl-10 mb-3">Situação</h6>
                </span>
              </div>
              <div class="col-md-4 col-12">
                <div class="form-input mb-3">
                  <div class="input-group mb-1">
                    <select v-if="role == 2" class="form-control" v-model="payment_status">
                      <option value="1">Aguardando Pagamento 1/2</option>
                      <option value="2">Pagamento Confirmado 1/2</option>
                      <option value="3">Aguardando pagamento RRT</option>
                      <option value="4">Pagamento RRT Confirmado</option>
                      <option value="5">Aguardando Pagamento 2/2</option>
                      <option value="6">Pago</option>
                    </select>
                    <input type="text" v-else :value="getStatusPay(payment_status)" disabled>
                  </div>
                </div>
              </div>
              <div v-if="role == 2" class="col-md-3 col-12">
                <button class="btn btn-primary btn-all" @click="updatePayment">
                  <span>Mudar</span>
                </button>
              </div>

            </div>
            <div class="row" v-if="payment_status != 6 || role == 2">
              <div class="col-12">
                <span class="form-label ">
                  <h6 class="pl-10 mb-3">Adicionar Anexo</h6>
                </span>
              </div>
              <div class="col-md-4 col-12">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label">
                    Nome
                  </label>
                  <div class="input-group mb-1">
                    <input v-model="anexo_name_13" type="text" class="form-control" name="anexo_name_13"
                      placeholder="Nome do arquivo" />
                  </div>
                </div>
              </div>
              <div class="col-md-4 col-12 mb-3">
                <div class="form-input">
                  <label for="formGroupExampleInput" class="form-label">
                    Arquivo
                  </label>
                </div>
                <input readonly id="anexo_13" ref="anexo_13" type="file" class="form-control" name="anexo_13" />
              </div>
              <div class="col-md-3 col-12">
                <div class="form-input">
                  <label for="formGroupExampleInput" class="form-label"></label>
                </div>
                <button class="btn btn-primary btn-all" @click="upload(13)">
                  <span>Adicionar</span>
                </button>
              </div>
            </div>
            <table>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Nome</th>
                  <th>Criação</th>
                  <th>Ação</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="attachment in attachments_payment" :key="attachment.id">
                  <td>{{ attachment.id }}</td>
                  <td>{{ attachment.attach_name }}</td>
                  <td>{{ formatDate(0, attachment.created_at) }}</td>
                  <td class="actions-icon">
                    <a target="_blank" :href="baseStorage + attachment.path" download>
                      <span data-bs-toggle="tooltip" tooltip-bs-title="Ver detalhes do projeto"
                        class="bx bx-detail ml-5 mr-5 icon-primary">
                      </span>
                    </a>
                    <span v-if="role == 2" data-bs-toggle="tooltip" tooltip-bs-title="Deletar documento"
                      class="bx bx-trash ml-5 mr-5 icon-primary" @click="deletarDoc(attachment.id, 13)">
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="bottom-data">
          <div class="orders">
            <div class="w-100 d-flex align-items-center justify-content-between gap-3">
              <div class="header">
                <i class="bx bx-file"></i>
                <h3>Vistoria</h3>
              </div>
              <div class="d-flex gap-2">
                <button v-if="status == 6 && role != 2" class="btn btn-primary btn-all" @click="updateStatus(1)">
                  <span>Solicitar Vistoria</span>
                </button>
                <button v-if="status == 10 && role == 2" class="btn btn-primary btn-all" @click="updateStatus(2)">
                  <span>Enviar Solicitação de Vistoria</span>
                </button>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-12">
                <span class="form-label ">
                  <h6 class="pl-10 mb-3">Situação</h6>
                </span>
              </div>
              <div class="col-4">
                <div class="form-input mb-3">
                  <input type="text" v-if="status == 7 || status == 10" :value="statusType(status)" disabled>
                  <input type="text" v-if="status >= 8 && status <= 9" value="Vistoria Realizada" disabled>
                  <input type="text" v-if="status < 7" value="Sem Vistoria" disabled>
                </div>
              </div>
            </div>
            <div class="row" v-if="status == 6 || status == 10 || role == 2">
              <div class="col-12">
                <span class="form-label ">
                  <h6 class="pl-10 mb-3">Adicionar Anexo</h6>
                </span>
              </div>
              <div class="col-md-4 col-12">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label">
                    Nome
                  </label>
                  <div class="input-group mb-1">
                    <input v-model="anexo_name_14" type="text" class="form-control" name="anexo_name_14"
                      placeholder="Nome do arquivo" />
                  </div>
                </div>
              </div>
              <div class="col-md-4 col-12 mb-3">
                <div class="form-input">
                  <label for="formGroupExampleInput" class="form-label">
                    Arquivo
                  </label>
                </div>
                <input readonly id="anexo_14" ref="anexo_14" type="file" class="form-control" name="anexo_14" />
              </div>
              <div class="col-md-3 col-12">
                <div class="form-input">
                  <label for="formGroupExampleInput" class="form-label"></label>
                </div>
                <button class="btn btn-primary btn-all" @click="upload(14)">
                  <span>Adicionar</span>
                </button>
              </div>
            </div>
            <table>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Nome</th>
                  <th>Criação</th>
                  <th>Ação</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="attachment in attachments_survey" :key="attachment.id">
                  <td>{{ attachment.id }}</td>
                  <td>{{ attachment.attach_name }}</td>
                  <td>{{ formatDate(0, attachment.created_at) }}</td>
                  <td class="actions-icon">
                    <a target="_blank" :href="baseStorage + attachment.path" download>
                      <span data-bs-toggle="tooltip" tooltip-bs-title="Ver detalhes do projeto"
                        class="bx bx-detail ml-5 mr-5 icon-primary">
                      </span>
                    </a>
                    <span v-if="role == 2 || status == 1" data-bs-toggle="tooltip" tooltip-bs-title="Deletar documento"
                      class="bx bx-trash ml-5 mr-5 icon-primary" @click="deletarDoc(attachment.id, 14)">
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import Cookie from 'js-cookie'
import NavBar from "@/components/NavBar.vue";
import Sidebar from "@/components/SideBar.vue";

import L from "leaflet";
import "leaflet/dist/leaflet.css";

import proj4 from "proj4";


import markerIcon from "leaflet/dist/images/marker-icon.png";
import markerShadow from "leaflet/dist/images/marker-shadow.png";

// Configura os ícones
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconUrl: markerIcon,
  shadowUrl: markerShadow,
});

export default {
  components: {
    NavBar,
    Sidebar
  },
  data() {
    return {
      role: this.$store.state.role,
      isReadonly: true,
      project_name: '',
      consumer_unit: '',
      email: '',
      uc_holder_name: '',
      cpf_cnpj: '',
      rg: '',
      phone: '',
      street: '',
      address_number: '',
      neighborhood: '',
      city: '',
      state: '',
      cep: '',
      company_name: '',
      observation: '',
      has_attachment: '',
      complement: '',
      type: 1,
      is_legal_entity: 0,
      status: 0,
      payment_status: 1,
      is_cpf: true,
      isSearch: false,
      token: Cookie.get('_token'),

      intergrators: [],

      //Tipo Solar
      id_customer_category: 0,
      id_connection_type: 0,
      id_circuit_breaker: 0,
      id_cable_size: 0,
      module_mark: '',
      module_model: '',
      module_power: '',
      module_quantity: '',
      inverter_mark: '',
      inverter_model: '',
      inverter_power: '',
      inverter_quantity: '',
      transformer_power: '',

      credited_ucs_quantity: '',
      latitude: '',
      longitude: '',
      installation_date: '',
      connection_date: '',
      modality: 0,
      class_group: 0,
      subgroup: 0,


      //Financeiro
      amount_project: 0,
      amount_art_trt: 0,
      amount_paid: 0,

      anexo_name: '',

      attachments: [],
      attachments_payment: [],
      attachments_survey: [],

      config: {
        masked: false,
        prefix: 'R$ ',
        suffix: '',
        thousands: '.',
        decimal: ',',
        precision: 2,
        disableNegative: false,
        disabled: (this.$store.state.role == 2) ? false : true,
        min: null,
        max: null,
        allowBlank: false,
        minimumNumberOfCharacters: 0,
        shouldRound: true,
        focusOnRight: false,
      },
      initialize_map: true,
      map: null,
      marker: null,
      defaultLatitude: -10.9472,
      defaultLongitude: -62.8272,
      utm: { zone: "", easting: "", northing: "" }, // Dados UTM
    };
  },
  methods: {
    statusType(statusValue) {
      switch (statusValue) {
        case 1:
          return 'Aberto';
        case 2:
          return 'Em progresso';
        case 3:
          return 'Protocolado';
        case 4:
          return 'Em Parecer de Acesso';
        case 5:
          return 'Obra em andamento';
        case 6:
          return 'Aprovado';
        case 7:
          return 'Vistoria solicitada';
        case 8:
          return 'Finalizado';
        case 9:
          return 'Cancelado';
        case 10:
          return 'Aguardando Solicitação de Vistoria';
        default:
          return 'Status desconhecido';
      }
    },
    clear() {
      this.project_name = '';
      this.consumer_unit = '';
      this.uc_holder_name = '';
      this.cpf_cnpj = '';
      this.rg = '';
      this.phone = '';
      this.street = '';
      this.address_number = '';
      this.neighborhood = '';
      this.city = '';
      this.state = '';
      this.cep = '';
      this.company_name = '';
      this.observation = '';
      this.has_attachment = '';
      this.complement = '';
      this.type = 0;
      this.is_legal_entity = 0;
      this.status = 0;
      this.payment_status = 1;
      this.is_cpf = true;

      //Info Solar
      this.id_customer_category = 0;
      this.id_connection_type = 0;
      this.id_circuit_breaker = 0;
      this.id_cable_size = 0;
      this.module_model = '';
      this.module_power = '';
      this.module_quantity = '';
      this.inverter_model = '';
      this.inverter_power = '';
      this.inverter_quantity = '';

    },
    showAlert(type, msg) {
      var textMsg = "";

      if (msg.message) {


        if (typeof msg.errors === 'object' && msg.errors) {
          for (const key in msg.errors) {
            if (Object.prototype.hasOwnProperty.call(msg.errors, key)) {
              const errorMessages = msg.errors[key];
              textMsg += `${errorMessages.join(', ')}<br>`;
            }
          }
        } else {
          textMsg = `${msg.message}`;
        }
      } else {
        textMsg = `${msg}`;
      }

      this.$swal({
        icon: type,
        confirmButtonText: 'Confirmar',
        html: textMsg
      });
    },
    amountConvert(value) {
      return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    },
    legalEntity() {
      this.is_cpf = !this.is_cpf;
      this.cpf_cnpj = '';
    },
    deleteConfirm() {
      this.$swal({
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim, quero deletar",
        icon: "warning",
        html: "Tem certeza que deseja deletar?"
      }).then((result) => {
        if (result.isConfirmed) {
          this.delete();
        }
      });
    },
    delete() {
      this.$http
        .delete("user/projects/" + this.$route.params.projeto, {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            "Authorization": "Bearer " + this.token
          },
        }).then((response) => {
          this.showAlert('success', response.data.message);
          this.$router.push('/projetos/solar');
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            this.showAlert('error', error.response.data);
          } else {
            this.showAlert('error', '[01] Ocorreu um erro desconhecido. Entre em contato com a equipe de desenvolvimento.');
          }
        });
    },
    deletarDoc(doc, type) {

      this.$swal({
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim, quero deletar",
        icon: "warning",
        html: "Tem certeza que deseja deletar o anexo?"
      }).then((result) => {
        if (result.isConfirmed) {

          let json = {
            doc: doc,
            project: this.$route.params.projeto
          }

          this.$http
            .post("user/projects/doc/delete", json, {
              headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                "Authorization": "Bearer " + this.token
              },
            }).then((response) => {
              this.showAlert('success', response.data.message);
              this.reloadAttach(type);
            })
            .catch((error) => {
              if (error.response && error.response.data) {
                this.showAlert('error', error.response.data);
              } else {
                this.showAlert('error', '[02] Ocorreu um erro desconhecido. Entre em contato com a equipe de desenvolvimento.');
              }
            });
        }
      });
    },
    update() {

      if (this.type == 0) {
        return this.showAlert('error', 'Informa o tipo de projeto');
      }

      var json = {
        project_name: this.project_name,
        consumer_unit: this.consumer_unit,
        uc_holder_name: this.uc_holder_name,
        cpf_cnpj: this.cpf_cnpj,
        rg: this.rg,
        phone: this.phone,
        street: this.street,
        address_number: this.address_number,
        neighborhood: this.neighborhood,
        city: this.city,
        state: this.state,
        zip_code: this.cep,
        company_name: this.company_name,
        observation: this.observation,
        has_attachment: this.has_attachment,
        complement: this.complement,
        project_type: this.type,
        is_legal_entity: this.is_legal_entity,
        id_state: this.status,
        email: this.email,
        //Solar
        id_customer_category: this.id_customer_category,
        id_connection_type: this.id_connection_type,
        id_circuit_breaker: this.id_circuit_breaker,
        id_cable_size: this.id_cable_size,
        module_mark: this.module_mark,
        module_model: this.module_model,
        module_power: this.module_power,
        module_quantity: this.module_quantity,
        inverter_mark: this.inverter_mark,
        inverter_model: this.inverter_model,
        inverter_power: this.inverter_power,
        inverter_quantity: this.inverter_quantity,
        transformer_power: this.transformer_power,

        credited_ucs_quantity: this.credited_ucs_quantity,
        latitude: this.latitude,
        longitude: this.longitude,
        installation_date: this.installation_date,
        connection_date: this.connection_date,
        modality: this.modality,
        class_group: this.class_group,
        subgroup: this.subgroup,

        //Financeiro
        amount_project: this.amount_project,
        amount_art_trt: this.amount_art_trt,
        amount_paid: this.amount_paid
      };

      this.$http
        .put("user/projects/" + this.$route.params.projeto, json, {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            "Authorization": "Bearer " + this.token
          },
        }).then((response) => {
          this.showAlert('success', response.data.message);
          this.isSearch = false;
          this.load();
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            this.showAlert('error', error.response.data);
          } else {
            this.showAlert('error', '[03] Ocorreu um erro desconhecido. Entre em contato com a equipe de desenvolvimento.');
          }
        });
    },
    search() {
      if (!this.cep || this.cep.length < 9) {

        return;
      }

      this.$http.get('/cep/' + this.cep)
        .then((response) => {

          this.street = response.data.logradouro;
          this.neighborhood = response.data.bairro;
          this.city = response.data.localidade;
          this.state = response.data.uf;
        }).catch((error) => {
          if (error.response && error.response.data) {
            this.showAlert('error', error.response.data);
          } else {
            this.showAlert('error', '[04] Ocorreu um erro desconhecido. Entre em contato com a equipe de desenvolvimento.');
          }
        })
    },
    formatDate(type, date) {
      if (!date) {
        return '';
      }

      const dateFormat = new Date(date);

      if (type == 0) {
        return `${dateFormat.getDate().toString().padStart(2, '0')}/${(dateFormat.getMonth() + 1).toString().padStart(2, '0')}/${dateFormat.getFullYear()}`;
      } else {

        return `${dateFormat.getDate().toString().padStart(2, '0')}/${(dateFormat.getMonth() + 1).toString().padStart(2, '0')}/${dateFormat.getFullYear()} às ${dateFormat.getHours().toString().padStart(2, '0')}:${dateFormat.getMinutes().toString().padStart(2, '0')}`;
      }

    },
    load() {

      this.$http.get("user/projects/" + this.$route.params.projeto, {
        headers: {
          "Authorization": "Bearer " + this.token
        },
      }).then((response) => {

        this.project_name = response.data.project.project_name;
        this.consumer_unit = response.data.project.consumer_unit;
        this.email = response.data.project.email;
        this.uc_holder_name = response.data.project.uc_holder_name;
        this.cpf_cnpj = response.data.project.cpf_cnpj;
        this.rg = response.data.project.rg;
        this.phone = response.data.project.phone;
        this.street = response.data.project.street;
        this.address_number = response.data.project.address_number;
        this.neighborhood = response.data.project.neighborhood;
        this.city = response.data.project.city;
        this.state = response.data.project.state;
        this.cep = response.data.project.zip_code;
        this.company_name = response.data.project.company_name;
        this.observation = response.data.project.observation;
        this.has_attachment = response.data.project.has_attachment;
        this.complement = response.data.project.complement;
        this.type = response.data.project.project_type;
        this.is_legal_entity = response.data.project.is_legal_entity;

        if (response.data.project.is_legal_entity == 1) {
          this.is_cpf = false;
        }

        this.status = response.data.project.id_state;

        this.payment_status = response.data.project.payment_status;

        //Solar
        this.id_customer_category = response.data.project_extra.id_customer_category;
        this.id_connection_type = response.data.project_extra.id_connection_type;
        this.id_circuit_breaker = response.data.project_extra.id_circuit_breaker;
        this.id_cable_size = response.data.project_extra.id_cable_size;

        this.module_mark = response.data.project_extra.module_mark;
        this.module_model = response.data.project_extra.module_model;
        this.module_power = response.data.project_extra.module_power;
        this.module_quantity = response.data.project_extra.module_quantity;

        this.inverter_mark = response.data.project_extra.inverter_mark;
        this.inverter_model = response.data.project_extra.inverter_model;
        this.inverter_power = response.data.project_extra.inverter_power;
        this.inverter_quantity = response.data.project_extra.inverter_quantity;
        this.transformer_power = response.data.project_extra.transformer_power;

        this.credited_ucs_quantity = response.data.project_extra.credited_ucs_quantity;
        this.latitude = response.data.project_extra.latitude;
        this.longitude = response.data.project_extra.longitude;
        this.installation_date = response.data.project_extra.installation_date;
        this.connection_date = response.data.project_extra.connection_date;
        this.modality = response.data.project_extra.modality;
        this.class_group = response.data.project_extra.class_group;
        this.subgroup = response.data.project_extra.subgroup;

        this.attachments = response.data.attachments;

        this.amount_project = response.data.financial.amount_project;
        this.amount_art_trt = response.data.financial.amount_art_trt;
        this.amount_paid = response.data.financial.amount_paid;

        if (this.role == 2 || this.status == 1) {
          const inputs = this.$el.querySelectorAll('input[readonly]');
          this.isReadonly = false;
          inputs.forEach(input => {
            input.removeAttribute('readonly');
          });
        }

        this.autoUser();
        this.isSearch = true;

        this.initializeCoordinates();

        if (this.initialize_map == true) {
          this.initializeMap();
        }

       this.updateUTM();

      })
        .catch((error) => {
          if (error.response && error.response.data) {
            this.showAlert('error', error.response.data);
          } else {
            this.showAlert('error', '[06] Ocorreu um erro desconhecido. Entre em contato com a equipe de desenvolvimento.');
          }
        });
    },
    loadAttach() {
      this.$http.get("user/projects/doc/list/" + this.$route.params.projeto, {
        headers: {
          "Authorization": "Bearer " + this.token
        },
      }).then((response) => {
        this.attachments = response.data.attachments;
        this.attachments_payment = response.data.attachments_payment;
        this.attachments_survey = response.data.attachments_survey;
      })
        .catch((error) => {
          if (error.response && error.response.data) {
            this.showAlert('error', error.response.data);
          } else {
            this.showAlert('error', '[07] Ocorreu um erro desconhecido. Entre em contato com a equipe de desenvolvimento.');
          }
        });
    },
    reloadAttach(type) {

      let json = {
        project: this.$route.params.projeto,
        type: type
      }

      this.$http.post("user/projects/doc/list", json, {
        headers: {
          "Authorization": "Bearer " + this.token
        },
      }).then((response) => {

        if (type == 12) {
          this.attachments = response.data.attachments;
        }

        if (type == 13) {
          this.attachments_payment = response.data.attachments;
        }

        if (type == 14) {
          this.attachments_survey = response.data.attachments;
        }

      })
        .catch((error) => {
          if (error.response && error.response.data) {
            this.showAlert('error', error.response.data);
          } else {
            this.showAlert('error', '[08] Ocorreu um erro desconhecido. Entre em contato com a equipe de desenvolvimento.');
          }
        });
    },
    updateContent(event) {
      this.observation = event.target.innerHTML;
    },
    autoUser() {

      if (!this.isSearch) return;

      if (this.is_legal_entity == 0 && this.cpf_cnpj.length < 14 || this.is_legal_entity == 1 && this.cpf_cnpj.length < 18) {
        return;
      }

      var json = {
        'number': this.cpf_cnpj,
        'type': this.is_legal_entity
      }

      this.$http.post('/user/search', json, {
        headers: {
          "Content-Type": "multipart/form-data",
          "Authorization": "Bearer " + this.token
        },
      })
        .then((response) => {

          if (!response.data.status) {
            return;
          }


          this.uc_holder_name = response.data.items.full_name;
          this.rg = response.data.items.rg;
          this.phone = response.data.items.phone;
          this.state = response.data.items.state;

          this.integrator = response.data.items.integrators;
          this.is_legal_entity = response.data.items.is_legal_entity;

          if (this.is_legal_entity) {
            this.company_name = response.data.extra.company_name;
          }

          this.address_number = response.data.items.address_number;
          this.street = response.data.items.street;
          this.neighborhood = response.data.items.neighborhood;
          this.city = response.data.items.city;
          this.state = response.data.items.state;
          this.cep = response.data.items.zip_code;
          this.complement = response.data.items.complement;

          const inputs = this.$el.querySelectorAll('input[readonly]');

          inputs.forEach(input => {
            input.removeAttribute('readonly');
          });

        }).catch((error) => {
          if (error.response && error.response.data) {
            this.showAlert('error', error.response.data);
          } else {
            this.showAlert('error', '[09] Ocorreu um erro desconhecido. Entre em contato com a equipe de desenvolvimento.');
          }
        })
    },
    upload(type) {

      let nameFile;
      let anexoFile;

      if (type == 12) {
        nameFile = this.anexo_name_12;
        anexoFile = this.$refs.anexo_12.files[0];
      }

      if (type == 13) {
        nameFile = this.anexo_name_13;
        anexoFile = this.$refs.anexo_13.files[0];
      }

      if (type == 14) {
        nameFile = this.anexo_name_14;
        anexoFile = this.$refs.anexo_14.files[0];
      }

      var json = {
        name: nameFile,
        id_project: this.$route.params.projeto,
        anexo: anexoFile,
        type: type
      };

      let loader = this.$loading.show({
        container: this.fullPage,
        canCancel: false,
      });

      this.$http
        .post("user/projects/doc", json, {
          headers: {
            "Content-Type": "multipart/form-data",
            "Authorization": "Bearer " + this.token
          },
        })
        .then((response) => {
          this.showAlert('success', response.data.message);
          this.reloadAttach(type);
          loader.hide()
        })
        .catch((error) => {
          loader.hide()
          if (error.response && error.response.data) {
            this.showAlert('error', error.response.data);
          } else {
            this.showAlert('error', '[10] Ocorreu um erro desconhecido. Entre em contato com a equipe de desenvolvimento.');
          }

        });
    },
    updateStatus(type) {

      var json = {
        id_project: this.$route.params.projeto,
        type: type,
      };

      let loader = this.$loading.show({
        container: this.fullPage,
        canCancel: false,
      });

      this.$http
        .post("user/projects/survey", json, {
          headers: {
            "Content-Type": "multipart/form-data",
            "Authorization": "Bearer " + this.token
          },
        })
        .then((response) => {
          this.showAlert('success', response.data.message);
          this.isSearch = false;
          this.load();
          loader.hide()
        })
        .catch((error) => {
          loader.hide()
          if (error.response && error.response.data) {
            this.showAlert('error', error.response.data);
          } else {
            this.showAlert('error', '[11] Ocorreu um erro desconhecido. Entre em contato com a equipe de desenvolvimento.');
          }

        });
    },
    updatePayment() {

      var json = {
        id_project: this.$route.params.projeto,
        status: this.payment_status,
      };

      let loader = this.$loading.show({
        container: this.fullPage,
        canCancel: false,
      });

      this.$http
        .post("user/projects/payment", json, {
          headers: {
            "Content-Type": "multipart/form-data",
            "Authorization": "Bearer " + this.token
          },
        })
        .then((response) => {
          this.showAlert('success', response.data.message);
          loader.hide()
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            this.showAlert('error', error.response.data);
          } else {
            this.showAlert('error', '[12] Ocorreu um erro desconhecido. Entre em contato com a equipe de desenvolvimento.');
          }
          loader.hide()
        });
    },
    getStatusPay(status) {
      switch (status) {
        case 1:
          return 'Aguardando Pagamento 1/2';
        case 2:
          return 'Pagamento Confirmado 1/2';
        case 3:
          return 'Aguardando pagamento RRT';
        case 4:
          return 'Pagamento RRT Confirmado';
        case 5:
          return 'Aguardando Pagamento 2/2';
        case 6:
          return 'Pago';
      }
    },
    convertToDMS(decimal) {
      const degrees = Math.floor(Math.abs(decimal));
      const minutes = Math.floor((Math.abs(decimal) - degrees) * 60);
      const seconds = ((Math.abs(decimal) - degrees - minutes / 60) * 3600).toFixed(2);
      const direction = decimal >= 0 ? (this.latitude === decimal ? "N" : "O") : (this.latitude === decimal ? "S" : "L");

      return `${degrees}° ${minutes}' ${seconds}" ${direction}`;
    },
    initializeCoordinates() {
      if (!this.latitude) {
        this.latitude = this.defaultLatitude;
      }

      if (!this.longitude) {
        this.longitude = this.defaultLongitude;
      }

      console.log(this.latitude, this.longitude)

    },
    initializeMap() {
      this.initialize_map = false;

      // Inicializa o mapa
      this.map = L.map("map").setView([this.latitude, this.longitude], 13);

      // Adiciona o tile do mapa
      L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
        attribution: '',
      }).addTo(this.map);

      // Adiciona um marcador inicial
      this.marker = L.marker([this.latitude, this.longitude], {
        draggable: true,
      }).addTo(this.map);

      // Atualiza os inputs quando o marcador for movido
      this.marker.on("dragend", (event) => {
        const { lat, lng } = event.target.getLatLng();
        this.latitude = lat.toFixed(6);
        this.longitude = lng.toFixed(6);

        this.updateUTM();
      });
    },
    updateMarker() {
      // Atualiza a posição do marcador no mapa
      if (this.marker) {
        this.marker.setLatLng([this.latitude, this.longitude]);
        this.map.setView([this.latitude, this.longitude], this.map.getZoom());
      }
    },
    getUTMLetter(latitude) {
      latitude = parseFloat(latitude); // Garante que é um número

      if (latitude >= 84) return "X"; // Latitude máxima do sistema UTM
      if (latitude < -80) return "C"; // Latitude mínima do sistema UTM

      const letters = "CDEFGHJKLMNPQRSTUVWX"; // Letras válidas no sistema UTM
      const index = Math.floor((latitude + 80) / 8); // Divide a latitude em faixas de 8 graus

      if (index < 0 || index >= letters.length) {
        console.error(`Latitude fora do intervalo permitido (-80 a 84): ${latitude}`);
        return "Invalid"; // Valor inválido para zonas fora do alcance
      }

      console.log(`Latitude: ${latitude}, Index: ${index}, Letter: ${letters[index]}`);
      return letters[index];
    },

    updateUTM() {
      const zoneNumber = Math.floor((parseFloat(this.longitude) + 180) / 6) + 1; // Calcula o número da zona
      const zoneLetter = this.getUTMLetter(parseFloat(this.latitude)); // Garante que a latitude é um número

      if (zoneLetter === "Invalid") {
        console.error("Letra da zona não foi calculada corretamente.");
        return; // Interrompe a execução em caso de erro
      }

      const [easting, northingRaw] = proj4("EPSG:4326", `+proj=utm +zone=${zoneNumber} +datum=WGS84 +units=m +no_defs`, [
        parseFloat(this.longitude),
        parseFloat(this.latitude),
      ]);

      // Ajusta o Northing para o hemisfério sul
      const northing = this.latitude >= 0 ? northingRaw : northingRaw + 10000000;

      console.log(`Zone Number: ${zoneNumber}, Zone Letter: ${zoneLetter}`);
      console.log(`Easting: ${easting}, Northing: ${northing}`);

      // Atualiza os valores UTM
      this.utm = {
        zone: `${zoneNumber} ${zoneLetter}`, // Ex.: "20 L"
        easting: `${easting.toFixed(2)} m E`,
        northing: `${northing.toFixed(2)} m ${this.latitude >= 0 ? "N" : "S"}`,
      };
    }
  },
  created() {
    this.load();
    this.loadAttach();
  }
};
</script>
