<template>
  <div :class="{ sidebar: true, close: sidebarClosed }">
    <div class="menu-top">
      <router-link to="/" class="logo">
        <img v-if="!darkMode" class="logo-name" src="@/assets/images/logo_inoveng.png" alt="Logo">
        <img v-if="darkMode" class="logo-name" src="@/assets/images/logo_inoveng_white.png" alt="Logo">
      </router-link>

      <div class="close-sidebar">
        <i class="bx bx-menu" @click="toggleSidebar"></i>
      </div>
    </div>
    <ul class="side-menu">
      <li :class="{ active: $route.path === '/' }" >
        <router-link to="/">
          <i class="bx bxs-dashboard"></i>
          Inicio
        </router-link>
      </li>
      <li :class="{ active: $route.path.startsWith('/projeto') }" v-if="role == 2 || role == 1">
        <router-link to="/projeto">
          <i class="bx bx-message-square-dots"></i>
          Projetos
        </router-link>
      </li>
      <li :class="{ active: $route.path.startsWith('/clientes') }" v-if="role == 2 || role == 1">
        <router-link to="/clientes">
          <i class="bx bx-group"></i>
          Clientes
        </router-link>
      </li>
      <li :class="{ active: $route.path.startsWith('/vendedores') }" v-if="role == 2 || role == 1">
        <router-link to="/vendedores">
          <i class='bx bx-store-alt'></i>
          Vendedores
        </router-link>
      </li>
      <li v-if="role == 2" :class="{ active: $route.path.startsWith('/integradores') }">
        <router-link to="/integradores">
          <i class="bx bxs-user-voice"></i>
          Integradores
        </router-link>
      </li>
      <li :class="{ active: $route.path.startsWith('/kits') }" v-if="role == 2 || role == 1">
        <router-link to="/kits">
          <i class='bx bx-bolt-circle'></i>
          Kits Solar
        </router-link>
      </li>
      <li :class="{ active: $route.path.startsWith('/orcamentos') }">
        <router-link to="/orcamentos">
          <i class='bx bx-note'></i>
          Orçamentos
        </router-link>
      </li>
      <li :class="{ active: $route.path.startsWith('/leads') }" v-if="role == 2 || role == 1">
        <router-link to="/leads">
          <i class='bx bxs-bookmarks'></i>
          Leads
        </router-link>
      </li>
      <li :class="{ active: $route.path === '/financeiro' }" v-if="role == 2 || role == 1">
        <router-link to="/financeiro">
          <i class='bx bx-receipt'></i>
          Financeiro
        </router-link>
      </li>
      <li :class="{ active: $route.path === '/integracoes' }" v-if="role == 2">
        <router-link to="/integracoes">
          <i class='bx bxs-package'></i>
          Integrações
        </router-link>
      </li>
      <li :class="{ active: $route.path === '/config' }" >
        <router-link to="/config">
          <i class="bx bx-cog"></i>
          Minha Conta
        </router-link>
      </li>
    </ul>
    <ul class="side-menu">
      <li data-bs-title="Sair">
        <label href="" class="item logout" @click="logout">
          <i class="bx bx-log-out-circle"></i>
          Sair
        </label>
      </li>
    </ul>
  </div>
</template>

<script>
import Cookie from 'js-cookie'
export default {
  name: 'SideBar',
  data() {
    return {
      role: 0,
      darkMode: (Cookie.get('theme') == 'true')? true : false,
      sidebarClosed: (Cookie.get('sidebarClosed') == 'true')? true  : false,
    };
  },
  methods: {
    toggleSidebar() {
      this.sidebarClosed = !this.sidebarClosed;
      Cookie.set('sidebarClosed', this.sidebarClosed);
    },
    showAlert(type, msg) {
      var textMsg = "";

      if (msg.message) {
          if (typeof msg.errors === 'object' && msg.errors) {
          for (const key in msg.errors) {
              if (Object.prototype.hasOwnProperty.call(msg.errors, key)) {
              const errorMessages = msg.errors[key];
              textMsg += `${errorMessages.join(', ')}<br>`;
              }
          }
          } else {
          textMsg = `${msg.message}`;
          }
      } else {
          textMsg = `${msg}`;
      }

      this.$swal({
          text: textMsg,
          icon: type,
          confirmButtonText: 'Confirmar',
          html: textMsg
      });
    },
    logout(){
        this.showAlert('success', 'Você saiu da conta com sucesso!');
        Cookie.remove('_token');

        setTimeout(() => {
            this.$router.push('/login');
        }, 1000);
    }
  },
  created() {
    window.addEventListener("resize", () => {
      if (window.innerWidth < 768) {
        this.sidebarClosed = true;
      } else {
        this.sidebarClosed = false;
      }
    });

    this.role = this.$store.state.role;
  },
  computed: {

  },
};
</script>
