<template>
  <div>
    <Sidebar />
    <div class="content">
      <NavBar />
      <main>
        <div class="header">
          <div class="left">
            <h1>Novo Cliente</h1>
          </div>
        </div>
        <div class="bottom-data">
          <div class="orders">
            <div class="header">
              <i class="bx bx-receipt"></i>
              <h3>Informações</h3>
            </div>
            <div class="row">
              <div class="col-md-5 col-12">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label">
                    Nome do Cliente
                  </label>
                  <input v-model="full_name" type="text" class="form-control" name="full_name"
                    placeholder="Nome do cliente" />
                </div>
              </div>
              <div class="col-md-3 col-6">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label">
                    CPF
                  </label>
                  <input v-maska="'###.###.###-##'" v-model="cpf" class="form-label" />
                </div>
              </div>
              <div class="col-md-2 col-6">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label">
                    RG
                  </label>
                  <input v-model="rg" type="text" class="form-control" name="rg" placeholder="0123456" />
                </div>
              </div>
              <div class="col-md-2 col-12">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label">
                    Será Empresa?
                  </label>
                  <div class="input-group mb-1">
                    <select class="form-control" v-model="is_legal_entity" @change="legalEntity">
                      <option value="0">Não</option>
                      <option value="1">Sim</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="col-md-4 col-6">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label">
                    E-mail
                  </label>
                  <input v-model="email" type="email" class="form-control" name="email" placeholder="teste@teste.com" />
                </div>
              </div>
              <div v-if="!is_cpf" class="col-md-5 col-6">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label">
                    Nome da Empresa
                  </label>
                  <input v-model="company_name" type="text" class="form-control" name="rg" placeholder="Razão Social" />
                </div>
              </div>
              <div v-if="!is_cpf" class="col-md-3 col-6">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label">
                    CNPJ
                  </label>
                  <input v-maska="'##.###.###/####-##'" v-model="cnpj" class="form-label" />
                </div>
              </div>

              <div class="col-md-3 col-6">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label">
                    Telefone
                  </label>
                  <input v-maska="'(##) #####-####'" v-model="phone" class="form-label" />
                </div>
              </div>
              <div class="col-md-2 col-6">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label">
                    CEP
                  </label>
                  <input v-maska="'#####-###'" v-model="cep" class="form-label" @change="search" />
                </div>
              </div>
              <div class="col-md-3 col-6">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label">
                    Cidade
                  </label>
                  <input v-model="city" type="text" class="form-control" name="city" placeholder="Aguardando..."
                    readonly />
                </div>
              </div>
              <div class="col-md-2 col-6">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label">
                    Estado
                  </label>
                  <input v-model="state" type="text" class="form-control" name="state" placeholder="Aguardando..."
                    readonly />
                </div>
              </div>
              <div class="col-md-2 col-6">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label">
                    Numero
                  </label>
                  <input v-model="address_number" type="text" class="form-control" name="address_number"
                    placeholder="Ex: 10" />
                </div>
              </div>
              <div class="col-md-5 col-12">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label">
                    Logradouro
                  </label>
                  <input v-model="street" type="text" class="form-control" name="street" placeholder="Aguardando..."
                    readonly />
                </div>
              </div>
              <div class="col-md-3 col-6">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label">
                    Bairro
                  </label>
                  <input v-model="neighborhood" type="text" class="form-control" name="neighborhood"
                    placeholder="Aguardando..." readonly />
                </div>
              </div>

              <div class="col-md-3 col-12">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label">
                    Complemento
                  </label>
                  <input v-model="complement" type="text" class="form-control" name="complement"
                    placeholder="Ex: Casa, APT, Sítio, etc" />
                </div>
              </div>
              <div class="col-md-3 col-12">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label">
                    Estado Civil
                  </label>
                  <input v-model="marital_status" type="text" class="form-control" name="complement"
                    placeholder="Ex: Solteiro" />
                </div>
              </div>
              <div class="col-md-3 col-12">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label">
                    Nacionalidade
                  </label>
                  <input v-model="nationality" type="text" class="form-control" name="complement"
                    placeholder="Ex: Brasileiro" />
                </div>
              </div>
            </div>
            <div class="col-md-4 col-12" v-if="role == 2">
              <div class="form-input mb-3">
                <label for="formGroupExampleInput" class="form-label">
                  Qual será o Integrador?
                </label>
                <div class="input-group mb-1">
                  <select class="form-control" v-model="integrator">
                    <option value="0">Escolher...</option>
                    <option v-for="intergrator in intergrators" :key="intergrator.id" :value="intergrator.id">
                      {{ (intergrator.company_name) ? intergrator.company_name : intergrator.full_name }}</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="w-100 d-flex align-items-center justify-content-end mt-5">
              <button class="btn btn-primary btn-all" @click="create">
                <span>Criar Conta</span>
              </button>
            </div>
          </div>
        </div>
      </main>
    </div>

  </div>
</template>

<script>
import Cookie from 'js-cookie'
import NavBar from "@/components/NavBar.vue";
import Sidebar from "@/components/SideBar.vue";

export default {
  components:{
    NavBar,
    Sidebar,
  },
  data() {
    return {
      full_name: '',
      cpf: '',
      rg: '',
      cnpj: '',
      is_legal_entity: 0,
      integrator:0,
      email: '',
      phone: '',
      cep: '',
      address_number: '',
      street: '',
      neighborhood: '',
      city: '',
      state: '',
      company_name: '',
      complement: '',
      is_cpf: true,
      token: Cookie.get('_token'),
      intergrators: [],
      role: this.$store.state.role,
    };
  },
  methods: {
    clear() {
      this.full_name = '';
      this.integrator = 0;
      this.email= '';
      this.cpf= '';
      this.rg= '';
      this.phone= '';
      this.street= '';
      this.address_number= '';
      this.neighborhood= '';
      this.city= '';
      this.state= '';
      this.cep= '';
      this.company_name= '';
      this.complement= '';
      this.cnpj = '',
      this.is_legal_entity = 0;
      this.is_cpf= true;
    },
    showAlert(type, msg) {
      var textMsg = "";

      if (msg.message) {


        if (typeof msg.errors === 'object' && msg.errors) {
          for (const key in msg.errors) {
            if (Object.prototype.hasOwnProperty.call(msg.errors, key)) {
              const errorMessages = msg.errors[key];
              textMsg += `${errorMessages.join(', ')}<br>`;
            }
          }
        } else {
          textMsg = `${msg.message}`;
        }
      } else {
        textMsg = `${msg}`;
      }

      this.$swal({
        icon: type,
        confirmButtonText: 'Confirmar',
        html: textMsg
      });
    },
    amountConvert(value) {
      return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    },
    legalEntity() {
      this.is_cpf = !this.is_cpf;
    },
    create() {

      var json = {
        full_name: this.full_name,
        cpf: this.cpf,
        rg: this.rg,
        email: this.email,
        phone: this.phone,
        street: this.street,
        address_number: this.address_number,
        neighborhood: this.neighborhood,
        city: this.city,
        state: this.state,
        cnpj: this.cnpj,
        cep: this.cep,
        marital_status: this.marital_status,
        nationality: this.nationality,
        company_name: this.company_name,
        complement: this.complement,
        is_legal_entity: this.is_legal_entity,
        integrator: this.integrator
      };

      this.$http
        .post("user/clients", json, {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            "Authorization": "Bearer " + this.token
          },
        })
        .then((response) => {
          //this.clear();
          this.showAlert('success', response.data.message);
          this.$router.push('/clientes');
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            this.showAlert('error', error.response.data);
          } else {
            this.showAlert('error', 'Ocorreu um erro desconhecido. Entre em contato com a equipe de desenvolvimento.');
          }

        });
    },
    loadIntergrator() {
      this.$http.get("user/integrators", {
        headers: {
          "Authorization": "Bearer " + this.token
        },
      })
        .then((response) => {
          this.intergrators = response.data.items;
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            this.showAlert('error', error.response.data);
          } else {
            this.showAlert('error', 'Ocorreu um erro desconhecido. Entre em contato com a equipe de desenvolvimento. 2');
          }

        });
    },
    search(){
      if(!this.cep || this.cep.length < 9){
        
        return;
      }

      this.$http.get('/cep/'+this.cep)
      .then( (response) =>{
        console.log(response);
        this.street= response.data.logradouro;
        this.neighborhood= response.data.bairro;
        this.city= response.data.localidade;
        this.state= response.data.uf;

        const inputs = this.$el.querySelectorAll('input[readonly]');

        inputs.forEach(input => {
          input.removeAttribute('readonly');
        });

      }).catch((error) => {
        if (error.response && error.response.data) {
            this.showAlert('error', error.response.data);
        } else {
          this.showAlert('error', 'Ocorreu um erro desconhecido. Entre em contato com a equipe de desenvolvimento.');
        }
      })
    },
  },
  created() {
    this.loadIntergrator();
  }
};
</script>
