<template>
  <div>
    <Sidebar />
    <div class="content">
      <NavBar />
      <main>
        <div class="header">
          <div class="left">
            <h1>Integrações</h1>
          </div>
        </div>
        <div class="bottom-data" v-if="role == 1 || role == 2">
          <div class="orders">
            <div class="header">
              <i class='bx bxl-google-cloud' ></i>
              <h3>Google Drive</h3>
            </div>
            <div class="row">
              <div class="col-md-4 col-12">
                <div class="form-input mb-3">
                  <label for="" class="form-label">Client ID</label>
                  <input v-model="google.client_id" type="text" class="form-control"/>
                </div>
              </div>
              <div class="col-md-4 col-12">
                <div class="form-input mb-3">
                  <label for="" class="form-label">Client Secret</label>
                  <input v-model="google.client_secret" type="text" class="form-control"/>
                </div>
              </div>
              <div class="col-md-4 col-12">
                <div class="form-input mb-3">
                  <label for="" class="form-label">Token</label>
                  <input v-model="google.refresh_token" type="text" class="form-control"/>
                </div>
              </div>
            </div>
            <div class="w-100 d-flex align-items-center justify-content-end mt-5">
              <button class="btn btn-primary btn-all" @click="googleChange">
                <span>Alterar</span>
              </button>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>
<script>

import NavBar from "@/components/NavBar.vue";
import Sidebar from "@/components/SideBar.vue";
import Cookies from "js-cookie";

export default {

  components: {
    NavBar,
    Sidebar,
  },
  data() {
    return {
      role: this.$store.state.role,
      google: {
        client_id: '',
        client_secret: '',
        refresh_token: '',
      },
      token: Cookies.get('_token')
    };
  },
  methods: {
    showAlert(type, msg) {
      var textMsg = "";

      if (msg.message) {
        if (typeof msg.errors === 'object' && msg.errors) {
          for (const key in msg.errors) {
            if (Object.prototype.hasOwnProperty.call(msg.errors, key)) {
              const errorMessages = msg.errors[key];
              textMsg += `${errorMessages.join(', ')}<br>`;
            }
          }
        } else {
          textMsg = `${msg.message}`;
        }
      } else {
        textMsg = `${msg}`;
      }

      this.$swal({
        text: textMsg,
        icon: type,
        confirmButtonText: 'Confirmar',
        html: textMsg
      });
    },
    load() {
      this.$http.get("user/integration/google", {
        headers: {
          Authorization: "Bearer " + this.token,
        },
      })
        .then((response) => {
            this.google.client_id = response.data.client_id;
            this.google.client_secret = response.data.client_secret;
            this.google.refresh_token = response.data.refresh_token;
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            this.showAlert("error", error.response.data);
          } else {
            this.showAlert(
              "error",
              "Ocorreu um erro desconhecido. Entre em contato com a equipe de desenvolvimento."
            );
          }
        });
    },

    googleChange() {
      var json = {
        client_id: this.google.client_id,
        client_secret: this.google.client_secret,
        refresh_token: this.google.refresh_token,
      }

      this.$http.post("user/integration/google", json, {
        headers: {
          Authorization: "Bearer " + this.token,
        },
      })
        .then((response) => {
          this.showAlert("success", response.data.message);
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            this.showAlert("error", error.response.data);
          } else {
            this.showAlert(
              "error",
              "Ocorreu um erro desconhecido. Entre em contato com a equipe de desenvolvimento."
            );
          }
        });
    },

  },
  created() {
    this.load();
  },
  watch: {

  },
  mounted() {
  }
};
</script>
