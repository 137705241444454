<template>
  <div>
    <Sidebar />
    <div class="content">
      <NavBar />
      <main>
        <div class="header">
          <div class="left">
            <h1>Minha conta</h1>
          </div>
        </div>
        <div class="bottom-data" v-if="role == 1 || role == 2">
          <div class="orders">
            <div class="header">
              <i class="bx bx-cog"></i>
              <h3>Configurações Gerais</h3>
            </div>
            <div class="row mb-3 mt-4">
              <div class="col-md-6 col-12">
                <div class="mb-3">
                  <label for="" class="form-label">Logotipo</label>
                  <input id="logotipo" ref="logotipo" type="file" class="form-control" name="logotipo" />
                </div>
                <div class="w-100 d-flex align-items-center justify-content-end mt-">
                  <button class="btn btn-primary btn-all" @click="changeLogo">
                    <span>Alterar</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="bottom-data" v-if="role == 1 || role == 2">
          <div class="orders">
            <div class="header">
              <i class="bx bx-coin"></i>
              <h3>Taxas</h3>
            </div>
            <div class="row">
              <div class="col-md-4 col-12">
                <div class="form-input mb-3">
                  <label for="" class="form-label">Tarifa</label>
                  <input v-model="tarifa" type="number" class="form-control" name="tarifa" placeholder="0"
                    step="0.01" />
                </div>
              </div>
              <div class="col-md-4 col-12">
                <div class="form-input mb-3">
                  <label for="" class="form-label">Valor Minimo do WP</label>
                  <input v-model="minWP" type="number" class="form-control" name="minWP" placeholder="0" step="0.01" />
                </div>
              </div>
              <div class="col-md-4 col-12">
                <div class="form-input mb-3">
                  <label for="" class="form-label">Valor Maximo do WP</label>
                  <input v-model="maxWP" type="number" class="form-control" name="maxWP" placeholder="0" step="0.01" />
                </div>
              </div>
              <div class="col-md-4 col-12">
                <div class="form-input mb-3">
                  <label for="" class="form-label">Minima Insolação</label>
                  <input v-model="minInsolation" type="number" class="form-control" name="minInsolation" placeholder="0"
                    step="0.01" />
                </div>
              </div>
              <div class="col-md-4 col-12">
                <div class="form-input mb-3">
                  <label for="" class="form-label">Maxima Insolação</label>
                  <input v-model="maxInsolation" type="number" class="form-control" name="maxInsolation" placeholder="0"
                    step="0.01" />
                </div>
              </div>
            </div>
            <div class="w-100 d-flex align-items-center justify-content-end mt-5">
              <button class="btn btn-primary btn-all" @click="tax">
                <span>Alterar</span>
              </button>
            </div>
          </div>
        </div>
        <div class="bottom-data" v-if="role == 1 || role == 2">
          <div class="orders">
            <div class="header">
              <i class="bx bx-receipt"></i>
              <h3>Parametros de Proposta</h3>
            </div>
            <div class="row">
              
              <div class="col-md-2 col-12">
                <div class="form-input mb-3">
                  <label for="" class="form-label">Distância (Km)</label>
                  <input v-model="distance" type="number" class="form-control" name="distance" placeholder="0"
                    step="0.01" />
                </div>
              </div>
              <div class="col-md-2 col-12">
                <div class="form-input mb-3">
                  <label for="" class="form-label">Valor por Km (R$)</label>
                  <money3 v-model="value_distance" v-bind="configMask"></money3>
                </div>
              </div>

              <div class="col-md-2 col-12">
                <div class="form-input mb-3">
                  <label for="" class="form-label">Impostos (%)</label>
                  <input v-model="taxesP" type="number" class="form-control" name="taxes" placeholder="0" step="0.01" />
                </div>
              </div>
              <div class="col-12">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label">
                    Formas de Pagamento
                  </label>
                  <textarea ref="editor" v-model="payment_table"></textarea>
                </div>
              </div>
            </div>

            <div class="w-100 d-flex align-items-center justify-content-end mt-5">
              <button class="btn btn-primary btn-all" @click="params">
                <span>Alterar</span>
              </button>
            </div>
          </div>
        </div>
        <div class="bottom-data">
          <div class="orders">
            <div class="header">
              <i class="bx bx-receipt"></i>
              <h3>Alterar senha</h3>
            </div>
            <div class="row">
              <div class="col-md-6 col-12">
                <div class="form-input mb-3">
                  <label for="" class="form-label"> Nova senha </label>
                  <input v-model="new_password" type="password" class="form-control" name="passnew"
                    placeholder="Digite a nova senha." />
                </div>
              </div>
              <div class="col-md-6 col-12">
                <div class="form-input mb-3">
                  <label for="" class="form-label"> Repetir nova senha </label>
                  <input v-model="renew_password" type="password" class="form-control" name="repassnew"
                    placeholder="Repita a  nova senha." />
                </div>
              </div>
            </div>
            <div class="w-100 d-flex align-items-center justify-content-end mt-5">
              <button class="btn btn-primary btn-all" @click="change">
                <span>Alterar</span>
              </button>
            </div>
          </div>
        </div>
        <div class="bottom-data"  v-if="role == 1 || role == 2">
          <div class="orders">
            <div class="header">
              <i class="bx bx-receipt"></i>
              <h3>Procuração</h3>
            </div>
            <div class="row">
              <div class="col-md-3 col-12">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label">
                    Tipo de procuração
                  </label>
                  <div class="input-group mb-1">
                    <select class="form-control" v-model="is_legal_entity">

                      <option value="0">Escolher o tipo</option>
                      <option value="1">Pessoa Física</option>
                      <option value="2">Entidade Juridica</option>
                    </select>
                  </div>
                </div>
              </div>
              <div v-show="is_legal_entity == 1" class="col-12">
                <div class="form-input mt-3">
                  <div class="main-container">
                    <div class="editor-container editor-container_classic-editor" ref="editorContainerElement">
                      <div class="editor-container__editor">
                        <div ref="editorElement">
                          <ckeditor v-if="isLayoutReady" v-model="editorData" :editor="editor" :config="config" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-show="is_legal_entity == 2" class="col-12">
                <div class="form-input mt-3">
                  <div class="main-container">
                    <div class="editor-container editor-container_classic-editor" ref="editorContainerElement">
                      <div class="editor-container__editor">
                        <div ref="editorElement">
                          <ckeditor v-if="isLayoutReady" v-model="editorDataLegal" :editor="editor" :config="config" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div class="row">
              <div class="col-md-4 col-12 mt-4">
                <label for="formGroupExampleInput" class="form-label">
                  Variaveis:
                </label>
                <div class="input-group mb-1" v-pre>
                  <code class="w-50">{{ $nome }}</code> <br />
                  <code class="w-50">{{ $estado_civil }}</code><br />
                  <code class="w-50">{{ $nacionalidade }}</code><br />
                  <code class="w-50">{{ $cpf }}</code><br />
                  <code class="w-50">{{ $rg }}</code><br />
                  <code class="w-50">{{ $endereco }}</code><br />
                  <code class="w-50">{{ $numero }}</code><br />
                  <code class="w-50">{{ $bairro }}</code><br />
                  <code class="w-50">{{ $cidade }}</code><br />
                  <code class="w-50">{{ $estado }}</code><br />
                  <code class="w-50">{{ $cep }}</code><br />
                  <code class="w-50">{{ $razao_social }}</code><br />
                  <code class="w-50">{{ $cnpj }}</code><br />
                  <code class="w-50">{{ $data }} </code><br />
                </div>

              </div>
            </div>
            <div class="w-100 d-flex align-items-center justify-content-end mt-5">
              <button class="btn btn-primary btn-all" @click="changeDoc">
                <span>Alterar Procuracao</span>
              </button>
            </div>
          </div>
        </div>
        <div v-if="role == 2" class="bottom-data">
          <div class="orders">
            <div class="header">
              <i class="bx bx-receipt"></i>
              <h3>Memorial Descritivo</h3>
            </div>
            <div class="row">
              <div class="col-md-3 col-12">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label">
                    Tipo de memorial
                  </label>
                  <div class="input-group mb-1">
                    <select class="form-control" v-model="is_memorial_entity">
                      <option value="0">Escolher o tipo</option>
                      <option value="1">Pessoa Física</option>
                      <option value="2">Entidade Juridica</option>
                    </select>
                  </div>
                </div>
              </div>
              <div v-show="is_memorial_entity == 1" class="col-12">
                <div class="form-input mt-3">
                  <div class="main-container">
                    <div class="editor-container editor-container_classic-editor" ref="editorContainerElement">
                      <div class="editor-container__editor">
                        <div ref="editorElement">
                          <ckeditor v-if="isLayoutReadyMemorial" v-model="editorMemorial" :editor="editor"
                            :config="config" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-show="is_memorial_entity == 2" class="col-12">
                <div class="form-input mt-3">
                  <div class="main-container">
                    <div class="editor-container editor-container_classic-editor" ref="editorContainerElement">
                      <div class="editor-container__editor">
                        <div ref="editorElement">
                          <ckeditor v-if="isLayoutReadyMemorial" v-model="editorMemorialLegal" :editor="editor"
                            :config="config" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 mt-4">
                <label for="formGroupExampleInput" class="form-label">
                  Variaveis:
                </label>
                <div class="input-group mb-1" v-pre>
                  <code class="w-25">{{ $nome_projeto }} </code><br />
                  <code class="w-25">{{ $estado_civil }} </code><br />
                  <code class="w-25">{{ $nacionalidade }} </code><br />
                  <code class="w-25">{{ $cpf }} </code><br />
                  <code class="w-25">{{ $rg }} </code><br />
                  <code class="w-25">{{ $endereco }} </code><br />
                  <code class="w-25">{{ $numero }} </code><br />
                  <code class="w-25">{{ $bairro }} </code><br />
                  <code class="w-25">{{ $cidade }} </code><br />
                  <code class="w-25">{{ $estado }} </code><br />
                  <code class="w-25">{{ $cep }} </code><br />
                  <code class="w-25">{{ $razao_social }} </code><br />
                  <code class="w-25">{{ $cnpj }} </code><br />
                  <code class="w-25">{{ $data }} </code><br />
                  <code class="w-25">{{ $nome_projeto }} </code><br />
                  <code class="w-25">{{ $unidade_consumidora }} </code><br />
                  <code class="w-25">{{ $tag_de_empresa }} </code><br />
                  <code class="w-25">{{ $nome_titular_uc }} </code><br />
                  <code class="w-25">{{ $email }} </code><br />
                  <code class="w-25">{{ $telefone }} </code><br />
                  <code class="w-25">{{ $logradouro }} </code><br />
                  <code class="w-25">{{ $complemento }} </code><br />
                  <code class="w-25">{{ $categoria_cliente }} </code><br />
                  <code class="w-25">{{ $tipo_ligacao_padrao }} </code><br />
                  <code class="w-25">{{ $disjuntor_padrao }} </code><br />
                  <code class="w-25">{{ $bitola_cabo_padrao }} </code><br />
                  <code class="w-25">{{ $marca_modulo }} </code><br />
                  <code class="w-25">{{ $modelo_modulo }} </code><br />
                  <code class="w-25">{{ $potencia_modulo }} </code><br />
                  <code class="w-25">{{ $qtd_modulo }} </code><br />
                  <code class="w-25">{{ $marca_inversor }} </code><br />
                  <code class="w-25">{{ $modelo_inversor }} </code><br />
                  <code class="w-25">{{ $potencia_inversor }} </code><br />
                  <code class="w-25">{{ $qtd_inversor }} </code><br />
                  <code class="w-25">{{ $potencia_transformador }} </code><br />
                  <code class="w-25">{{ $modalidade }} </code><br />
                  <code class="w-25">{{ $classes }} </code><br />
                  <code class="w-25">{{ $subgrupo }} </code><br />
                  <code class="w-25">{{ $data_implantacao_geradora }} </code><br />
                  <code class="w-25">{{ $data_conexao_geradora }} </code><br />
                  <code class="w-25">{{ $qtd_ucs_creditos }} </code><br />
                  <code class="w-25">{{ $latitude_decimais }} </code><br />
                  <code class="w-25">{{ $longitude_decimais }} </code><br />
                  <code class="w-25">{{ $latitude_gms }} </code><br />
                  <code class="w-25">{{ $longitude_gms }} </code><br />
                  <code class="w-25">{{ $zone }} </code><br />
                  <code class="w-25">{{ $longitude_utm }} </code><br />
                  <code class="w-25">{{ $latitude_utm }} </code><br />
                  <code class="w-25">{{ $observacao }} </code><br />
                  <code class="w-25">{{ $potencia_total_modulos }} </code><br />
                  <code class="w-25">{{ $area_total_arranjos }} </code><br />
                </div>
              </div>
            </div>
            <div class="w-100 d-flex align-items-center justify-content-end mt-5">
              <button class="btn btn-primary btn-all" @click="changeMemorial">
                <span>Alterar Memorial</span>
              </button>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>
<script>

import Cookie from 'js-cookie'
import NavBar from "@/components/NavBar.vue";
import Sidebar from "@/components/SideBar.vue";

import {
  ClassicEditor,
  AccessibilityHelp,
  Alignment,
  Autoformat,
  AutoLink,
  Autosave,
  BalloonToolbar,
  BlockQuote,
  Bold,
  Code,
  CodeBlock,
  Essentials,
  FindAndReplace,
  FontBackgroundColor,
  FontColor,
  FontFamily,
  FontSize,
  Heading,
  Highlight,
  HorizontalLine,
  Indent,
  IndentBlock,
  Italic,
  Link,
  Paragraph,
  RemoveFormat,
  SelectAll,
  SpecialCharacters,
  SpecialCharactersArrows,
  SpecialCharactersCurrency,
  SpecialCharactersEssentials,
  SpecialCharactersLatin,
  SpecialCharactersMathematical,
  SpecialCharactersText,
  Strikethrough,
  Subscript,
  Superscript,
  Table,
  TableCaption,
  TableCellProperties,
  TableColumnResize,
  TableProperties,
  TableToolbar,
  TextPartLanguage,
  TextTransformation,
  Underline,
  Undo
} from 'ckeditor5';

import translations from 'ckeditor5/translations/pt-br.js';

import 'ckeditor5/ckeditor5.css';

export default {

  components: {
    NavBar,
    Sidebar,
  },
  data() {
    return {
      role: this.$store.state.role,
      name: "",
      email: "",
      tarifa: 0,
      minWP: 0,
      maxWP: 0,
      minInsolation: 0,
      maxInsolation: 0,
      wpp: "",
      status: "",
      author: "",
      token: "",
      taxes: [],
      new_password: '',
      renew_password: '',
      editorData: '',
      editorDataLegal: '',
      editorMemorial: '',
      editorMemorialLegal: '',
      isLayoutReady: false, 
      isLayoutReadyMemorial: false,
      configEdit: {
        toolbar: {
          items: [
            'undo',
            'redo',
            '|',
            'findAndReplace',
            'textPartLanguage',
            '|',
            'heading',
            '|',
            'fontSize',
            'fontFamily',
            'fontColor',
            'fontBackgroundColor',
            '|',
            'bold',
            'italic',
            'underline',
            'strikethrough',
            'subscript',
            'superscript',
            'code',
            'removeFormat',
            '|',
            'specialCharacters',
            'horizontalLine',
            'link',
            'insertTable',
            'highlight',
            'blockQuote',
            'codeBlock',
            '|',
            'alignment',
            '|',
            'outdent',
            'indent'
          ],
          shouldNotGroupWhenFull: true
        },
        plugins: [
          AccessibilityHelp,
          Alignment,
          Autoformat,
          AutoLink,
          Autosave,
          BalloonToolbar,
          BlockQuote,
          Bold,
          Code,
          CodeBlock,
          Essentials,
          FindAndReplace,
          FontBackgroundColor,
          FontColor,
          FontFamily,
          FontSize,
          Heading,
          Highlight,
          HorizontalLine,
          Indent,
          IndentBlock,
          Italic,
          Link,
          Paragraph,
          RemoveFormat,
          SelectAll,
          SpecialCharacters,
          SpecialCharactersArrows,
          SpecialCharactersCurrency,
          SpecialCharactersEssentials,
          SpecialCharactersLatin,
          SpecialCharactersMathematical,
          SpecialCharactersText,
          Strikethrough,
          Subscript,
          Superscript,
          Table,
          TableCaption,
          TableCellProperties,
          TableColumnResize,
          TableProperties,
          TableToolbar,
          TextPartLanguage,
          TextTransformation,
          Underline,
          Undo
        ],
        balloonToolbar: ['bold', 'italic', '|', 'link'],
        fontFamily: {
          supportAllValues: true
        },
        fontSize: {
          options: [10, 12, 14, 'default', 18, 20, 22],
          supportAllValues: true
        },
        heading: {
          options: [
            {
              model: 'paragraph',
              title: 'Paragraph',
              class: 'ck-heading_paragraph'
            },
            {
              model: 'heading1',
              view: 'h1',
              title: 'Heading 1',
              class: 'ck-heading_heading1'
            },
            {
              model: 'heading2',
              view: 'h2',
              title: 'Heading 2',
              class: 'ck-heading_heading2'
            },
            {
              model: 'heading3',
              view: 'h3',
              title: 'Heading 3',
              class: 'ck-heading_heading3'
            },
            {
              model: 'heading4',
              view: 'h4',
              title: 'Heading 4',
              class: 'ck-heading_heading4'
            },
            {
              model: 'heading5',
              view: 'h5',
              title: 'Heading 5',
              class: 'ck-heading_heading5'
            },
            {
              model: 'heading6',
              view: 'h6',
              title: 'Heading 6',
              class: 'ck-heading_heading6'
            }
          ]
        },
        language: 'pt-br',
        link: {
          addTargetToExternalLinks: true,
          defaultProtocol: 'https://',
          decorators: {
            toggleDownloadable: {
              mode: 'manual',
              label: 'Downloadable',
              attributes: {
                download: 'file'
              }
            }
          }
        },
        table: {
          contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells', 'tableProperties', 'tableCellProperties']
        },
        translations: [translations]
      },
      editor2: null,
      content: "<p>Escreva algo aqui...</p>",
      ckeditorInstance: null,
      config2: null,
      editor: ClassicEditor,
      is_legal_entity: 0,
      is_memorial_entity: 0,
      p_min: 0,
      p_max: 0,
      amount_project: 0,
      amount_rrt: 0,
      distance: 0, 
      value_distance: 0,
      taxesP: 0,
      payment_table: '',
      configMask: {
        masked: false,
        prefix: 'R$ ',
        suffix: '',
        thousands: '.',
        decimal: ',',
        precision: 2,
        disableNegative: false,
        disabled: false,
        min: null,
        max: null,
        allowBlank: false,
        minimumNumberOfCharacters: 0,
        shouldRound: true,
        focusOnRight: false,
      }
    };
  },
  methods: {
    showAlert(type, msg) {
      var textMsg = "";

      if (msg.message) {
        if (typeof msg.errors === 'object' && msg.errors) {
          for (const key in msg.errors) {
            if (Object.prototype.hasOwnProperty.call(msg.errors, key)) {
              const errorMessages = msg.errors[key];
              textMsg += `${errorMessages.join(', ')}<br>`;
            }
          }
        } else {
          textMsg = `${msg.message}`;
        }
      } else {
        textMsg = `${msg}`;
      }

      this.$swal({
        text: textMsg,
        icon: type,
        confirmButtonText: 'Confirmar',
        html: textMsg
      });
    },
    load() {
      this.$http.get("user/tax", {
        headers: {
          Authorization: "Bearer " + this.token,
        },
      })
        .then((response) => {
          if (response.data.status) {
            this.tarifa = response.data.tax.tarifa.toFixed(2);
            this.minWP = response.data.tax.minWP.toFixed(2);
            this.maxWP = response.data.tax.maxWP.toFixed(2);
            this.minInsolation = response.data.tax.minInsolation.toFixed(2);
            this.maxInsolation = response.data.tax.maxInsolation.toFixed(2);
          }
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            this.showAlert("error", error.response.data);
          } else {
            this.showAlert(
              "error",
              "Ocorreu um erro desconhecido. Entre em contato com a equipe de desenvolvimento."
            );
          }
        });
    },
    loadDocs() {
      this.$http.get("user/userDocs", {
        headers: {
          Authorization: "Bearer " + this.token,
        },
      })
        .then((response) => {
          if (response.data.status) {
            this.editorData = response.data.doc;
            this.editorDataLegal = (response.data.doc_legal) ? response.data.doc_legal : '';

            this.editorMemorial = response.data.memorial;
            this.editorMemorialLegal = (response.data.memorial_legal) ? response.data.memorial_legal : '';

          }
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            this.showAlert("error", error.response.data);
          } else {
            this.showAlert(
              "error",
              "Ocorreu um erro desconhecido. Entre em contato com a equipe de desenvolvimento."
            );
          }
        });
    },
    loadParams() {
      this.$http.get("user/paramsBudget", {
        headers: {
          Authorization: "Bearer " + this.token,
        },
      })
        .then((response) => {
            this.distance = response.data.distance;
            this.value_distance = response.data.value_distance;
            this.taxesP = response.data.taxes;
            this.payment_table = (response.data.payment_table) ? response.data.payment_table : '';
        })
        .catch((error) => {
          if (error.response && error.response.data) {

            if(error.response.data.status){
              this.showAlert("error", error.response.data.message);
            }

          } else {
            this.showAlert(
              "error",
              "Ocorreu um erro desconhecido. Entre em contato com a equipe de desenvolvimento."
            );
          }
        });
    },
    change() {
      if (this.new_password != this.renew_password) {
        this.showAlert("error", "A nova senha e a confirmação não estão iguais.");
        return;
      }

      var json = {
        old: this.old_password,
        new: this.new_password,
        renew: this.renew_password,
      }

      this.$http.post("user/change", json, {
        headers: {
          Authorization: "Bearer " + this.token,
        },
      })
        .then((response) => {
          this.showAlert("success", response.data.message);
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            this.showAlert("error", error.response.data);
          } else {
            this.showAlert(
              "error",
              "Ocorreu um erro desconhecido. Entre em contato com a equipe de desenvolvimento."
            );
          }
        });
    },
    changeDoc() {
      if (!this.editorData) return this.showAlert("error", "A procuração está em branco!");

      var json = {
        type: this.is_legal_entity,
        doc_type: 1,
        doc: (this.is_legal_entity == 1) ? this.editorData : this.editorDataLegal,
      }

      this.$http.post("user/doc", json, {
        headers: {
          Authorization: "Bearer " + this.token,
        },
      })
        .then((response) => {
          this.showAlert("success", response.data.message);
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            this.showAlert("error", error.response.data);
          } else {
            this.showAlert(
              "error",
              "Ocorreu um erro desconhecido. Entre em contato com a equipe de desenvolvimento."
            );
          }
        });
    },
    changeMemorial() {
      if (!this.editorMemorial) return this.showAlert("error", "O memorial está em branco!");

      var json = {
        type: this.is_memorial_entity,
        doc_type: 2,
        doc: (this.is_memorial_entity == 1) ? this.editorMemorial : this.editorMemorialLegal,
      }

      this.$http.post("user/doc", json, {
        headers: {
          Authorization: "Bearer " + this.token,
        },
      })
        .then((response) => {
          this.showAlert("success", response.data.message);
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            this.showAlert("error", error.response.data);
          } else {
            this.showAlert(
              "error",
              "Ocorreu um erro desconhecido. Entre em contato com a equipe de desenvolvimento."
            );
          }
        });
    },
    tax() {
      var json = {
        tarifa: this.tarifa,
        minWP: this.minWP,
        maxWP: this.maxWP,
        minInsolation: this.minInsolation,
        maxInsolation: this.maxInsolation
      }

      this.$http.post("user/tax", json, {
        headers: {
          Authorization: "Bearer " + this.token,
        },
      })
        .then((response) => {
          this.showAlert("success", response.data.message);
          this.load();
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            this.showAlert("error", error.response.data);
          } else {
            this.showAlert(
              "error",
              "Ocorreu um erro desconhecido. Entre em contato com a equipe de desenvolvimento."
            );
          }
        });
    },
    params() {
      var json = {
        distance: this.distance,
        value_distance: this.value_distance,
        taxes: this.taxesP,
        payment_table: this.payment_table
      }

      this.$http.post("user/paramsBudget", json, {
        headers: {
          Authorization: "Bearer " + this.token,
        },
      })
        .then((response) => {
          this.showAlert("success", response.data.message);
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            this.showAlert("error", error.response.data);
          } else {
            this.showAlert(
              "error",
              "Ocorreu um erro desconhecido. Entre em contato com a equipe de desenvolvimento."
            );
          }
        });
    },
    changeLogo() {
      console.log("logo")
      var json = {
        type: 1,
        documentUpload: this.$refs.logotipo.files[0],
      }

      this.$http.post("user/upload", json, {
        headers: {
          "Content-Type": "multipart/form-data",
          "Accept": "application/json",
          Authorization: "Bearer " + this.token,
        },
      })
        .then((response) => {
          this.showAlert("success", response.data.message);
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            this.showAlert("error", error.response.data);
          } else {
            this.showAlert(
              "error",
              "Ocorreu um erro desconhecido. Entre em contato com a equipe de desenvolvimento."
            );
          }
        });
    },
    changeProcuration() {

      var json = {
        type: 2,
        documentUpload: this.$refs.procuracao.files[0],
      }

      this.$http.post("user/upload", json, {
        headers: {
          "Content-Type": "multipart/form-data",
          "Accept": "application/json",
          Authorization: "Bearer " + this.token,
        },
      })
        .then((response) => {
          this.showAlert("success", response.data.message);
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            this.showAlert("error", error.response.data);
          } else {
            this.showAlert(
              "error",
              "Ocorreu um erro desconhecido. Entre em contato com a equipe de desenvolvimento."
            );
          }
        });
    },
    initCKEditor() {
      if (!this.$refs.editor) {
        console.error("Elemento do editor ainda não está disponível.");
        return;
      }
      if (window.CKEDITOR) {
        this.ckeditorInstance = window.CKEDITOR.replace(this.$refs.editor, {
          toolbar: "Full",
          height: 100,
          versionCheck: false,
          extraPlugins: "uploadimage,colorbutton,font",
          removePlugins: "notification",
          filebrowserUploadUrl: "/upload",
          filebrowserBrowseUrl: "/browse",
        });

        setTimeout(() => {
          if (this.ckeditorInstance) {
            this.ckeditorInstance.resize("100%", 500);
          }
        }, 1000);

        this.ckeditorInstance.on("change", () => {
          this.payment_table = this.ckeditorInstance.getData();
        });

      } else {
        console.error("CKEDITOR não foi carregado corretamente.");
      }
    },
  },
  created() {
    
    if (Cookie.get('_token')) {
      this.token = Cookie.get('_token')
    }

    if(this.role == 1 || this.role == 2){
      this.load();
      this.loadDocs();
      this.loadParams();
    }

  },
  watch: {
    payment_table(newValue) {
      if (this.ckeditorInstance && this.ckeditorInstance.getData() !== newValue) {
        this.ckeditorInstance.setData(newValue);
      }
    },
  },
  mounted() {

    this.config = {
      toolbar: {
        items: [
          'undo',
          'redo',
          '|',
          'findAndReplace',
          'textPartLanguage',
          '|',
          'heading',
          '|',
          'fontSize',
          'fontFamily',
          'fontColor',
          'fontBackgroundColor',
          '|',
          'bold',
          'italic',
          'underline',
          'strikethrough',
          'subscript',
          'superscript',
          'code',
          'removeFormat',
          '|',
          'specialCharacters',
          'horizontalLine',
          'link',
          'insertTable',
          'highlight',
          'blockQuote',
          'codeBlock',
          '|',
          'alignment',
          '|',
          'outdent',
          'indent'
        ],
        shouldNotGroupWhenFull: true
      },
      plugins: [
        AccessibilityHelp,
        Alignment,
        Autoformat,
        AutoLink,
        Autosave,
        BalloonToolbar,
        BlockQuote,
        Bold,
        Code,
        CodeBlock,
        Essentials,
        FindAndReplace,
        FontBackgroundColor,
        FontColor,
        FontFamily,
        FontSize,
        Heading,
        Highlight,
        HorizontalLine,
        Indent,
        IndentBlock,
        Italic,
        Link,
        Paragraph,
        RemoveFormat,
        SelectAll,
        SpecialCharacters,
        SpecialCharactersArrows,
        SpecialCharactersCurrency,
        SpecialCharactersEssentials,
        SpecialCharactersLatin,
        SpecialCharactersMathematical,
        SpecialCharactersText,
        Strikethrough,
        Subscript,
        Superscript,
        Table,
        TableCaption,
        TableCellProperties,
        TableColumnResize,
        TableProperties,
        TableToolbar,
        TextPartLanguage,
        TextTransformation,
        Underline,
        Undo
      ],
      balloonToolbar: ['bold', 'italic', '|', 'link'],
      fontFamily: {
        supportAllValues: true
      },
      fontSize: {
        options: [10, 12, 14, 'default', 18, 20, 22],
        supportAllValues: true
      },
      heading: {
        options: [
          {
            model: 'paragraph',
            title: 'Paragraph',
            class: 'ck-heading_paragraph'
          },
          {
            model: 'heading1',
            view: 'h1',
            title: 'Heading 1',
            class: 'ck-heading_heading1'
          },
          {
            model: 'heading2',
            view: 'h2',
            title: 'Heading 2',
            class: 'ck-heading_heading2'
          },
          {
            model: 'heading3',
            view: 'h3',
            title: 'Heading 3',
            class: 'ck-heading_heading3'
          },
          {
            model: 'heading4',
            view: 'h4',
            title: 'Heading 4',
            class: 'ck-heading_heading4'
          },
          {
            model: 'heading5',
            view: 'h5',
            title: 'Heading 5',
            class: 'ck-heading_heading5'
          },
          {
            model: 'heading6',
            view: 'h6',
            title: 'Heading 6',
            class: 'ck-heading_heading6'
          }
        ]
      },
      language: 'pt-br',
      link: {
        addTargetToExternalLinks: true,
        defaultProtocol: 'https://',
        decorators: {
          toggleDownloadable: {
            mode: 'manual',
            label: 'Downloadable',
            attributes: {
              download: 'file'
            }
          }
        }
      },
      table: {
        contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells', 'tableProperties', 'tableCellProperties']
      },
      translations: [translations]
    };

    this.isParamsReady = true;
    this.isLayoutReady = true;
    this.isLayoutReadyMemorial = true;

    window.CKEDITOR.config.removePlugins = "notification,notificationaggregator";
  
    if (window.CKEDITOR && window.CKEDITOR.plugins) {
      window.CKEDITOR.plugins.checkVersion = function () {
        return true;
      };
    }

    this.$nextTick(() => {
      setTimeout(() => {
        if (this.$refs.editor) {
          this.initCKEditor();
        } else {
          console.error("Editor ainda não disponível no DOM.");
        }
      }, 500); 
    });
  }
};
</script>
